

































import Vue from 'vue'
import PresetsSidebar from '../components/sidebar/PresetsSidebar.vue'
import CMSPresetList from './CMSPresetList.vue'
import CMSPresetTags from './CMSPresetTags.vue'

export default Vue.extend({
  name: 'Presets',

  components: {
    PresetsSidebar,
    CMSPresetList,
    CMSPresetTags
  },

  props: {
    view: {
      type: String,
      default: 'presets'
    }
  },

  breadcrumbs () {
    return [{
      text: this.view === 'presets' ? 'cms.presets.presets' : 'cms.presets.tags',
      to: null
    }]
  },

  computed: {
    views (): Record<string, any>[] {
      return [{
        key: 'presets',
        icon: 'mdi-tune-vertical',
        component: CMSPresetList
      }, {
        key: 'tags',
        icon: 'mdi-shape',
        component: CMSPresetTags
      }]
    }
  }
})

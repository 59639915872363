














































































































import Vue from 'vue'
import { ContentPreset } from '@simpl/core/types/graphql'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'

export default Vue.extend({
  name: 'PresetTile',

  props: {
    preset: Object as () => ContentPreset
  },

  computed: {
    thumbnail (): string | undefined | null {
      return this.preset.thumb_desktop
    }
  },

  methods: {
    getTextForTemporaryUserLanguage
  }
})













































































import Vue from 'vue'
import {
  getTextForUserLanguage, objectHash, createTextWithFallback
} from '@simpl/core/utils'

import DomainSettingsSidebar from '../../components/DomainSettingsSidebar.vue'
import DomainSettingsBasic from './DomainSettingsBasic.vue'
import DomainSettingsGdpr from './DomainSettingsGdpr.vue'
import DomainSettingsAccount from './DomainSettingsAccount.vue'
import DomainSettingsMarkets from './DomainSettingsMarkets.vue'
import DomainSettingsLanguages from './DomainSettingsLanguages.vue'
import DomainSettingsNotifications from './DomainSettingsNotifications.vue'
import DomainSettingsHomePage from './DomainSettingsHomePage.vue'
import CMSPresetList from '../../../cms/views/CMSPresetList.vue'

import { Query, Tag, UpdateDomainInput, CreateTextInput } from '@simpl/core/types/graphql'
import { SHOW_DOMAIN, UPDATE_DOMAIN } from '../../graphql'
import { EditableDomain } from '../../types'
import DomainSettingsTheme from './DomainSettingsTheme.vue'
import AccessControlUserGroupsList from '../AccessControlUserGroupsList.vue'
import { MUTATIONS } from '@simpl/auth/store/consts'
import { unsavedChanges } from '@simpl/core/utils/message'
import CMSPresetTags from '@simpl/cms/views/CMSPresetTags.vue'

export type DomainEditUpdates = {
  lang: Record<string, DomainEditUpdates>
  name: string,
  [key: string]: any
}

export default Vue.extend({
  name: 'DomainSettings',

  components: {
    DomainSettingsSidebar
  },

  async beforeRouteLeave (to: any, _from: any, next: (target?: any) => void) {
    if (!this.hasChanged || this.loading > 0) {
      next()
      return
    }

    const userFeedback = await unsavedChanges(this)

    if (!userFeedback) {
      await this.$apollo.queries.domain.refetch()
      next()
      return
    }

    next(false)
  },

  props: {
    view: {
      type: String,
      default: 'basic'
    }
  },

  data () {
    return {
      id: this.$store.state.auth.user.active_domain.id,
      sidebarMini: false,
      showSidebar: true,

      formIsValid: true,

      loading: 0,

      updates: { lang: {} } as DomainEditUpdates,

      initialHash: null! as string,
      currentHash: null! as string,

      domain: null! as EditableDomain,

      showMarketValidationError: false,
      showLanguageValidationError: false
    }
  },

  computed: {
    views (): ({ key: string, icon: string, component: any, disabled?: boolean, hidden?: boolean, title?: string, class?: string })[] {
      return [{
        key: 'basic',
        icon: 'mdi-record-circle-outline',
        component: DomainSettingsBasic
      }, {
        key: 'account',
        icon: 'mdi-certificate',
        component: DomainSettingsAccount
      }, {
        key: 'usergroups',
        icon: 'mdi-account-multiple',
        component: AccessControlUserGroupsList
      }, {
        key: 'markets',
        icon: 'mdi-shopping',
        component: DomainSettingsMarkets,
        hidden: !this.$store.state.auth.domain.markets
      }, {
        key: 'languages',
        icon: 'mdi-translate',
        component: DomainSettingsLanguages
      }, {
        key: 'gdpr',
        icon: 'mdi-security',
        component: DomainSettingsGdpr
      }, {
        key: 'notifications',
        icon: 'mdi-message-text-outline',
        component: DomainSettingsNotifications
      }, {
        key: 'landingpage',
        icon: 'mdi-home-outline',
        component: DomainSettingsHomePage
      }, {
        key: 'theme',
        icon: 'mdi-shape-outline',
        component: DomainSettingsTheme
      }, {
        key: 'cms-presets',
        title: 'cms.presets.headline',
        icon: 'mdi-tune-vertical',
        component: CMSPresetList,
        hidden: !this.$permission.can(null, 'contentpreset-update'),
        class: this.view === 'evaluation' ? 'mainitem' : undefined
      }, {
        key: 'cms-tags',
        title: 'cms.presets.tags',
        icon: 'mdi-shape',
        component: CMSPresetTags,
        hidden: !this.$permission.can(null, 'contentpreset-update'),
        class: 'subitem'
      }]
    },

    canSave (): boolean {
      // eslint-disable-next-line
      return this.hasChanged &&
        this.formIsValid && !!(this.id) &&
        this.domain.tags.filter((tag: Tag) => tag.category!.identifier === 'market').length > 0 &&
        this.domain.tags.filter((tag: Tag) => tag.category!.identifier === 'language').length > 0
    },

    hasChanged (): boolean {
      const hasUpdates = Object.keys(this.updates).length > 1 || Object.keys(this.updates.lang).length > 0
      return hasUpdates || (this.initialHash !== this.currentHash)
    }
  },

  watch: {
    domain: {
      deep: true,
      handler (v: object): void {
        this.currentHash = objectHash(v)
        this.showMarketValidationError = !this.domain.tags.filter((tag: Tag) => tag.category!.identifier === 'market').length
        this.showLanguageValidationError = !this.domain.tags.filter((tag: Tag) => tag.category!.identifier === 'language').length
      }
    }
  },

  apollo: {
    domain: {
      query: SHOW_DOMAIN,

      skip (): boolean {
        return !this.id
      },

      fetchPolicy: 'no-cache',

      loadingKey: 'loading',

      variables (): object {
        return { id: this.id }
      },

      update (data: Query): EditableDomain {
        const domain = data.domain!

        if (!domain) {
          this.$router.replace('/404')
          return null!
        }
        domain.properties.themes = this.$store.state.auth.domain.properties.themes || []

        this.$store.commit(`auth/${MUTATIONS.SET_DOMAIN}`, domain)

        const retVal = {
          ...domain,
          name: getTextForUserLanguage(domain) || ''
        }

        this.initialHash = this.currentHash = objectHash(retVal)

        return retVal as any
      }
    }
  },

  methods: {
    createSlug (identifier: string): string {
      let result = ''
      const characters = identifier.replace('-', '') + 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    getMutationVariables (): UpdateDomainInput {
      const variables: UpdateDomainInput = {
        texts: {
          create: [],
          delete: []
        },
        tags: {
          sync: this.domain.tags.map(tag => tag.id)
        }
      } as any

      variables.namespace = this.domain.namespace

      if (this.domain.name?.length) {
        variables.texts!.create!.push(
          ...createTextWithFallback(this.domain.name!)
        )
      }

      if (this.domain.id) {
        variables.id = this.domain.id
      }

      if (this.domain.properties) {
        variables.properties = JSON.stringify(this.domain.properties)
      }

      if (Object.keys(this.updates.lang).length) {
        for (const lang in this.updates.lang) {
          for (const identifier in this.updates.lang[lang]) {
            const text: CreateTextInput = this.updates.lang[lang][identifier]

            if (text.text.length) {
              variables.texts!.create!.push(text)
            }

            if (!text.text.length) {
              const textId = this.domain.texts.find(t => t.identifier === text.identifier &&
                t.languagecode === text.languagecode)?.id

              if (textId) variables.texts!.delete?.push(textId)
            }
          }
        }
      }

      return variables
    },

    async cancel (): Promise<void> {
      if (this.hasChanged) {
        const stay = await unsavedChanges(this)

        if (!stay) {
          await this.$apollo.queries.domain.refetch()
          await this.$router.push('/')
        }
      } else {
        await this.$router.push('/')
      }
    },

    async save (): Promise<void> {
      this.loading += 1
      let hasErrors: Boolean = false

      const result = await this.$apollo.mutate({
        mutation: UPDATE_DOMAIN,

        variables: {
          data: this.getMutationVariables()
        }
      }).catch((error: Error) => {
        console.error(error.message, error.name)
        hasErrors = true
      })
      this.currentHash = this.initialHash

      if (!hasErrors && result) {
        await this.$apollo.queries.domain.refetch()

        this.$notification.publish('bottom', {
          message: this.$t('core.message.savedChanges'),
          type: 'success',
          color: 'success'
        })
        this.loading -= 1

        this.updates = { lang: {} } as DomainEditUpdates
      } else {
        this.loading -= 1
      }
    },

    reloadDomain (): void {
      this.$apollo.queries.domain.refetch()
    },

    formValidated (v: boolean): void {
      this.formIsValid = v
    }
  }
}
)

<template>
  <v-navigation-drawer
    permanent
    :mini-variant="mini"
    :width="$vuetify.breakpoint.xs ? 400 : 200"
    :mini-variant-width="$vuetify.breakpoint.xs ? 140 : 56"
    class="flex-grow-0 flex-shrink-0"
  >
    <template
      v-if="!$vuetify.breakpoint.xs"
      #append
    >
      <div
        class="d-flex"
        :class="mini ? 'mx-auto' : 'justify-end' "
      >
        <v-btn
          icon
          class="ma-2"
          :class="{ 'rotate-180': mini }"
          @click="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </template>

    <v-list
      class="py-0"
      tile
    >
      <v-tooltip
        v-for="view in views"
        :key="view.key"
        :disabled="!mini"
        right
      >
        <template #activator="{ on }">
          <v-list-item
            v-if="!view.hidden"
            :disabled="view.disabled"
            link
            :to="`/cms-presets/${view.key}`"
            :class="view.class ? view.class : undefined"
            :value="view.key"
            v-on="on"
          >
            <v-list-item-icon>
              <v-icon>
                {{ view.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`cms.presets.${view.key}`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <span>{{ $t(`cms.presets.${view.key}`) }}</span>
      </v-tooltip>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'AccessControlSidebar',

    props: {
      views: Array
    },

    data: () => ({
      mini: false
    }),

    created () {
      this.mini = this.$vuetify.breakpoint.xs
    }
  }
</script>

<style lang="scss" scoped>

</style>

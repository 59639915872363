















































































































































import { getTextForUserLanguage } from '@simpl/core/utils/text'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import mixins from 'vue-typed-mixins'
import EditPresetTagDialog from '../components/dialogs/EditPresetTagDialog.vue'
import { DELETE_TAG } from '@simpl/access-control/graphql'
import { Tag } from '@simpl/core/types/graphql'
import ContentPresetTags from '@simpl/core/mixins/apollo/ContentPresetTags'

export default mixins(StringToColor, ContentPresetTags).extend({
  name: 'CMSPresetTags',

  components: {
    EditPresetTagDialog
  },

  data: () => ({
    /** Displayed data */
    presetTags: [],
    presetTagsCategoryId: 0,
    loading: 0,

    /** Dialog control flow */
    dialog: false,
    selected: null! as Tag,
    editing: false,

    /** Search and filter */
    searchValue: '',
    sortBy: [],
    sortDesc: [],
    count: 1,
    page: 1,
    itemsPerPage: 10
  }),

  computed: {
    headers () {
      /** Table view */
      return [{
        text: this.$t('core.global.id'),
        align: 'left',
        value: 'id',
        width: 70
      }, {
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: '',
        value: 'actions',
        width: 110,
        class: 'separate-left',
        sortable: false
      }]
    },
    computedHeaders (): Record<string, any> {
      return this.headers.filter(header => !(this.$vuetify.breakpoint.xs && header.value === 'actions'))
    }
  },

  methods: {
    getTextForUserLanguage,

    add () {
      this.selected = null!
      this.editing = false
      this.dialog = true
    },
    edit (item: Tag) {
      this.selected = item
      this.editing = true
      this.dialog = true
    },
    async remove (id: string) {
      const answer = await this.$confirm({
        color: 'error',
        message: 'Are you sure? This action cannot be undone.',
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.delete'),
          color: 'error',
          answer: true
        }]
      })

      if (!answer) return

      this.loading += 1
      await this.$apollo.mutate({
        mutation: DELETE_TAG,
        variables: {
          id
        }
      })

      this.refetch()
      this.loading -= 1
    }
  }
})


















































































































































































import mixins from 'vue-typed-mixins'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import Roles from '@simpl/core/mixins/apollo/Roles'
import { EditableDomain } from '../../types'
import { getTextForUserLanguage } from '@simpl/core/utils'

export default mixins(StringToColor, Roles).extend({
  name: 'DomainSettingsGdpr',

  props: {
    domain: Object as () => EditableDomain
  },

  data () {
    return {
      loading: false,
      errorMessages: ''
    }
  },

  computed: {
    selfRegistrationEnabled: {
      get (): boolean {
        return this.domain.properties.self_registration?.enabled || false
      },
      set (v: boolean) {
        if (!this.domain.properties.self_registration) {
          this.$set(this.domain.properties, 'self_registration', {})
        }

        this.domain.properties.self_registration.enabled = v
      }
    }
  },

  methods: {
    getTextForUserLanguage
  }
})

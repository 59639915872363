








































































































































































































































































































































































































































































































































import mixins from 'vue-typed-mixins'
import {
  FilterColumn,
  OrderByClause,
  OrderByRelationClause,
  Query, Role,
  SortOrder,
  User, Tag
} from '@simpl/core/types/graphql'
import { DELETE_USER, INVITE, LIST_USERS, UPDATE_USER } from '../graphql'
import { IMPERSONATE } from '@simpl/auth/graphql'
import { ACTIONS } from '@simpl/auth/store/consts'
import { DataTableHeader } from '@simpl/core/types/table'
import { getTextForUserLanguage } from '@simpl/core/utils'
import { appCreated } from '@simpl/core/init-app'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import UserUpdateNotifications from '../mixins/UserUpdateNotifications'
import UserQuotaControl from '../mixins/UserQuotaControl'
import FilterView from '@simpl/core/components/FilterView.vue'
import ImportUsersDialog from '../components/ImportUsersDialog.vue'
import EditUserDialog from '../components/EditUserDialog.vue'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import LanguageTags from '@simpl/core/mixins/apollo/LanguageTags'
import UserGroupTags from '@simpl/core/mixins/apollo/UserGroupTags'
import Roles from '@simpl/core/mixins/apollo/Roles'
import { getLongLocaleDateTimeFormat } from '@simpl/core/utils/date-time-format'
import { RoleWithName, TagWithName, UserExtended } from '@simpl/core/types/extended-types'

function remapEntry (entry: User): UserExtended {
  const languageTags = entry.tags.filter(tag => tag.category!.identifier === 'language')

  return {
    ...entry,
    updatingActiveState: false,
    roleNames: entry.roles.map((role: Role) => getTextForUserLanguage(role)),
    primaryLanguageTag: languageTags.find(tag => tag.identifier === entry.languagecode)!,
    languages: languageTags,
    usergroups: entry.tags.filter(tag => tag.category!.identifier === 'usergroup')
  }
}
export default mixins(
  StringToColor, UserUpdateNotifications, UserQuotaControl, LanguageTags, UserGroupTags, Roles,
  UserSettings('userList', [
    'sortBy',
    'sortDesc',
    'selectedView',
    'itemsPerPage'
  ])
).extend({
  name: 'UserList',

  components: { FilterView, ImportUsersDialog, EditUserDialog },

  data () {
    return {
      users: [] as UserExtended[],

      searchValue: '',
      sortBy: [] as any[],
      sortDesc: [] as any[],
      count: 1,
      page: 1,
      itemsPerPage: 10,

      filterBy: [] as FilterColumn[],
      relationColumn: 'roles',
      relationData: {
        column: 'identifier',
        relation: 'roles'
      },

      loading: 0,
      showFilter: false,
      showImportUsersDialog: false,
      showEditUserDialog: false,
      selectedUser: null as UserExtended | null
    }
  },

  computed: {
    headers (): DataTableHeader[] {
      const headers = [{
        text: this.$vuetify.breakpoint.xs ? this.$t('core.global.status') : '',
        value: 'status',
        sortable: false,
        width: this.$vuetify.breakpoint.xs ? undefined : 30
      }, {
        text: this.$t('core.global.mail'),
        value: 'email'
      }, {
        text: this.$t('core.global.firstName'),
        value: 'firstname'
      }, {
        text: this.$t('core.global.lastName'),
        value: 'lastname'
      }, {
        text: this.$t('core.global.username'),
        value: 'username'
      }, {
        text: this.$t('core.global.language'),
        value: 'languagecode'
      }, {
        text: this.$t('core.global.roles'),
        value: 'roleNames',
        sortable: false
      }, {
        text: '',
        value: 'actions',
        class: 'separate-left',
        width: 210,
        sortable: false
      }, {
        text: '',
        value: 'data-table-expand'
      }] as DataTableHeader[]

      return headers.filter(header => !(this.$vuetify.breakpoint.xs && header.value === 'actions'))
    },
    filterOptions (): Record<string, any>[] {
      return [
        {
          type: 'chip-group',
          props: {
            headline: 'core.global.role',
            items: this.roles.map((role: Role) => {
              return {
                id: role.id,
                identifier: role.identifier,
                name: getTextForUserLanguage(role)
              }
            })
          },
          relationColumn: 'identifier',
          filterColumn: 'values',
          model: 'roles'
        },
        {
          type: 'chip-group',
          props: {
            headline: 'user.global.userGroups',
            items: this.userGroupTags.map((usergroup: Tag) => {
              return {
                id: usergroup.id,
                identifier: usergroup.identifier,
                name: getTextForUserLanguage(usergroup)
              }
            })
          },
          relationColumn: 'identifier',
          filterColumn: 'values',
          model: 'tags'
        },
        {
          type: 'autocomplete',
          props: {
            headline: 'core.global.languages',
            subheadline: 'filter.global.languages',
            label: 'core.action.selectLanguages',
            items: this.languageTags.map((language: Tag) => {
              return {
                id: language.id,
                identifier: language.identifier,
                name: getTextForUserLanguage(language)
              }
            })
          },
          relationColumn: 'identifier',
          filterColumn: 'values',
          model: 'tags'
        },
        {
          type: 'only-true-switch',
          props: {
            headline: 'core.global.status',
            label: 'filter.global.onlyActiveUsers'
          },
          filterColumn: 'values',
          model: 'active'
        },
        {
          type: 'radio-group',
          props: {
            headline: 'user.global.invitationStatus',
            items: [{
              value: true,
              label: 'filter.global.uninvited'
            }, {
              value: false,
              label: 'filter.global.invited'
            }]
          },
          filterNull: {
            true: 'isNull',
            false: 'notNull'
          },
          model: 'invitation_sent'
        }
      ]
    },

    orderBy (): OrderByClause[] {
      return this.sortBy
        .filter(key => key !== this.relationColumn)
        .map((key, index) => ({
          column: this.sortBy[index],
          order: this.sortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }))
    },
    orderByRelation (): OrderByRelationClause {
      const index = this.sortBy.indexOf(this.relationColumn)
      return index > -1
        ? {
          ...this.relationData,
          order: this.sortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }
        : {}
    },
    usersEnriched () {
      return this.users.map((item) => {
        if (item.is_super) {
          return {
            ...item,
            status: {
              icon: 'mdi-security',
              color: 'warning',
              text: this.$t('accessControl.status.superUser')
            }
          }
        }
        if (!item.active) {
          return {
            ...item,
            status: {
              icon: 'mdi-cancel',
              text: this.$t('accessControl.status.inactive')
            }
          }
        }
        if (!item.invitation_sent) {
          return {
            ...item,
            status: {
              icon: 'mdi-circle-outline',
              text: this.$t('accessControl.status.noInvite')
            }
          }
        }
        if (!item.email_verified_at) {
          return {
            ...item,
            status: {
              icon: 'mdi-circle-double',
              text: this.$t('accessControl.status.awaitingVerification')
            }
          }
        }
        return {
          ...item,
          status: {
            icon: 'mdi-circle',
            text: this.$t('accessControl.status.verified')
          }
        }
      })
    }
  },

  watch: {
    // Reset page, when searchValue has changed
    searchValue () {
      this.page = 1
    },
    showEditUserDialog (v) {
      if (!v) this.selectedUser = null
    }
  },

  apollo: {
    users: {
      query: LIST_USERS,

      variables () {
        return {
          filter: {
            search: {
              query: this.searchValue,
              columns: ['username', 'email', 'firstname', 'lastname']
            },
            filterBy: this.filterBy
          },
          orderBy: this.orderBy,
          orderByRelation: this.orderByRelation,
          page: this.page,
          first: this.itemsPerPage
        }
      },

      update (result: Query): UserExtended[] {
        const {
          total,
          currentPage,
          perPage
        } = result.users!.paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage

        return result.users!.data.map(remapEntry)
      },

      error (error: Error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    getTextForUserLanguage,
    getLongLocaleDateTimeFormat,

    async updateActiveState (user: UserExtended, value: boolean) {
      user.updatingActiveState = true

      await this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: {
          data: {
            active: value,
            id: user.id
          }
        }
      })

      user.updatingActiveState = false
      this.notifyActiveStateChange(user)
    },
    async invite (userId: string) {
      await this.$apollo.mutate({
        mutation: INVITE,
        variables: {
          data: { user_id: userId }
        }
      })

      await this.$apollo.queries.users.refetch()
      this.notifyUserInvited()
    },
    addOrRedirect () {
      this.noCapacity ? this.$router.push('domain/settings/account') : this.add()
    },
    add () {
      this.selectedUser = null
      this.showEditUserDialog = true
    },
    edit (user: UserExtended) {
      this.selectedUser = user
      this.showEditUserDialog = true
    },
    async remove (userId: string, email: string) {
      const confirm = await this.confirmDeleteRequest(email)

      if (!confirm) return

      const user = await this.$apollo.mutate({
        mutation: DELETE_USER,
        variables: {
          id: userId
        }
      })

      await this.$apollo.queries.users.refetch()
      this.notifyUserDeleted(user.data.detachOrDeleteUser)
    },
    async impersonate (userId: string) {
      this.notifyReloadRequired()

      try {
        const result = await this.$apollo.mutate({
          mutation: IMPERSONATE,
          variables: {
            data: {
              user_id: userId
            }
          }
        })

        if (result?.data) {
          await this.$router.push('/')
          // Force app into non-booted state to avoid duplicated execution of appCreated
          this.$store.state._booted = false
          await this.$store.dispatch(`auth/${ACTIONS.IMPERSONATE}`, result.data.impersonate)
          await appCreated(true)
        }
      } catch (e) {
        // TODO: error message
      }
    },
    async onSave () {
      await this.$apollo.queries.users.refetch()
      await this.$apollo.queries.domainQuotas.refetch()
    }
  }
})























































import Vue from 'vue'
import { createTextWithFallback, getTextForUserLanguage, nameToIdentifier } from '@simpl/core/utils/text'
import { CREATE_TAG, UPDATE_TAG } from '../graphql'
import { NewTagInput, Tag, UpdateTagInput } from '@simpl/core/types/graphql'
import { TranslateResult } from 'vue-i18n'
import { unsavedChanges } from '@simpl/core/utils/message'

export default Vue.extend({
  name: 'EditUserGroupDialog',

  props: {
    value: Boolean,
    id: [String, Number],
    edit: Boolean,
    preset: Object as () => Tag,
    usergroupCategoryId: [String, Number]
  },

  data: () => ({
    internalName: '',
    originalName: '',
    loading: 0
  }),

  computed: {
    title (): TranslateResult {
      return this.edit
        ? this.$t('accessControl.action.editUserGroup')
        : this.$t('accessControl.action.createUserGroup')
    },
    show: {
      get (): boolean { return this.value },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    hasChanged (): boolean {
      return this.originalName !== this.internalName
    },
    identifier (): string {
      return nameToIdentifier(this.internalName)
    }
  },

  watch: {
    show (v) {
      if (!v) return
      this.originalName = this.getPresetName()
      this.internalName = this.originalName
    }
  },

  methods: {
    getMutationData (): { data: UpdateTagInput | NewTagInput } {
      const data = {
        category: {
          connect: this.usergroupCategoryId
        },
        identifier: this.identifier,
        texts: {
          create: [
            ...createTextWithFallback(this.internalName)
          ]
        }
      } as UpdateTagInput | NewTagInput

      if (this.preset && this.edit) {
        (data as UpdateTagInput).id = this.preset.id
      }

      return {
        data
      }
    },
    getPresetName () {
      return getTextForUserLanguage(this.preset, 'display_name', false) || ''
    },
    async close () {
      this.show = this.hasChanged ? await unsavedChanges(this) : false
    },
    async save () {
      this.loading += 1

      await this.$apollo.mutate({
        mutation: this.preset && this.edit ? UPDATE_TAG : CREATE_TAG,
        variables: this.getMutationData()
      })

      this.loading -= 1
      this.show = false

      this.$emit('save')
    }
  }
})

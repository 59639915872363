import Vue from 'vue'
import { Query, Role } from '../../types/graphql'
import { LIST_ROLES_MINIMAL } from '@simpl/access-control/graphql'
import { getTextForUserLanguage } from '../../utils'
import { RoleWithName } from '../../types/extended-types'

export default Vue.extend({
  data: () => ({
    roles: [] as RoleWithName[]
  }),

  apollo: {
    roles: {
      query: LIST_ROLES_MINIMAL,

      // TODO: skip !hasOwn(this.domainQuotas, 'roles')
      skip (): boolean {
        return !this.$permission.can(null, 'role-view')
      },

      variables: {
        filter: {},
        first: 99999
      },

      update (result: Query): RoleWithName[] {
        return result.roles!.data.map((role: Role) => ({
          ...role,
          name: getTextForUserLanguage(role)
        }))
      },

      error (error: any) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  }
})

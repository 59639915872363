import Vue from 'vue'
import { User } from '@simpl/core/types/graphql'
import { UserExtended } from '@simpl/core/types/extended-types'

export default Vue.extend({
  name: 'UserUpdateNotifications',

  methods: {
    notifyActiveStateChange (user: UserExtended) {
      this.$notification.publish('bottom', {
        message: this.$t(`user.message.${user.active ? 'active' : 'inactive'}`, {
          firstname: user.firstname,
          lastname: user.lastname
        }),
        type: 'success',
        color: 'success'
      })
    },
    notifyUserInvited () {
      this.$notification.publish('bottom', {
        message: this.$t('user.message.invited'),
        type: 'success',
        color: 'success'
      })
    },
    notifyUserNotified () {
      this.$notification.publish('bottom', {
        message: this.$t('user.message.invited'),
        type: 'success',
        color: 'success'
      })
    },
    notifyUserCreatedOrUpdated (newUser?: boolean) {
      this.$notification.publish('bottom', {
        message: this.$t(`user.message.${newUser ? 'created' : 'edited'}`),
        type: 'success',
        color: 'success'
      })
    },
    notifyUserDeleted (user: User) {
      this.$notification.publish('bottom', {
        message: this.$t('user.message.deleted',
          {
            firstname: user.firstname,
            lastname: user.lastname
          }),
        color: 'success',
        type: 'success'
      })
    },
    notifyReloadRequired () {
      this.$notification.publish('bottom', {
        message: this.$t('core.message.changesRequireReload'),
        type: 'info',
        color: 'info'
      })
    },

    async confirmDeleteRequest (value: string) {
      return await this.$confirm({
        color: 'error',
        message: this.$t('core.message.deleteConfirm', [value]),
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.delete'),
          color: 'error',
          answer: true
        }]
      })
    }
  }
})

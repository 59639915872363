var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","fullscreen":_vm.$vuetify.breakpoint.xs,"max-width":"470","max-height":"90%"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('Headline',{staticClass:"headline",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('accessControl.action.importUsers'))+" ")])],1),_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('v-card-text',{staticClass:"pa-8"},[_vm._v(" "+_vm._s(_vm.$t('accessControl.message.importHint'))+" "),_c('v-btn',{staticClass:"mb-4",attrs:{"text":"","href":(_vm.publicPath + "downloads/example.xls")}},[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v(" mdi-cloud-download ")]),_vm._v(" "+_vm._s(_vm.$t('accessControl.action.downloadExampleXls'))+" ")],1),(_vm.show)?_c('FileUploader',{attrs:{"accept":".xlsx, .xls","aspect-ratio":1.66,"old-file-details":_vm.fileDetails,"verification-errors":_vm.fileVerificationErrors},on:{"update":_vm.onInput}}):_vm._e()],1)],1),_c('v-window-item',{attrs:{"value":2}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","sm":"10"}},[_vm._v(" "+_vm._s(_vm.$t('accessControl.message.importHintValid', [_vm.users.length]))+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","sm":"10"}},[_c('v-autocomplete',{staticClass:"py-2",attrs:{"label":_vm.$t('core.global.role'),"items":_vm.availableRoles,"item-text":"nameWithQuota","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"append-outer",fn:function(){return [(_vm.showQuotaWarning)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v(" mdi-alert-outline ")])]}}],null,false,3009369507)},[_vm._v(" "+_vm._s(_vm.$t('simpl-access-control.user-quota-exceeded'))+" ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.roleId),callback:function ($$v) {_vm.roleId=$$v},expression:"roleId"}}),_c('v-autocomplete',{staticClass:"py-2",attrs:{"label":_vm.$t('core.global.languages'),"items":_vm.activeDomainLanguageTags,"item-text":"name","item-value":"id","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c('v-chip',{staticClass:"white--text",style:(index > 0 ? 'margin-left: -10px;' : ''),attrs:{"color":_vm.stringToColor(item.identifier)}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text caption"},[_vm._v(_vm._s(("(+" + (_vm.selectedLanguageTagIds.length - 2) + " " + (_vm.$t('core.global.others')) + ")")))]):_vm._e()]}}]),model:{value:(_vm.selectedLanguageTagIds),callback:function ($$v) {_vm.selectedLanguageTagIds=$$v},expression:"selectedLanguageTagIds"}}),_c('v-autocomplete',{staticClass:"py-2",attrs:{"label":_vm.$t('core.global.primaryLanguage'),"items":_vm.languages,"filter":_vm.tagSelectFilter,"item-text":"name","item-value":"identifier","chips":""},model:{value:(_vm.languagecode),callback:function ($$v) {_vm.languagecode=$$v},expression:"languagecode"}}),(_vm.$store.state.auth.domain.markets)?_c('DomainMarketSelection',{attrs:{"restricted":!_vm.canAdministrate,"selectable-tags":_vm.currentUserMarkets,"rules":[_vm.required],"multiple":"","chips":""},model:{value:(_vm.selectedMarketTagIds),callback:function ($$v) {_vm.selectedMarketTagIds=$$v},expression:"selectedMarketTagIds"}}):_vm._e(),_c('v-autocomplete',{staticClass:"py-2",attrs:{"label":_vm.$t('user.global.userGroups'),"items":_vm.userGroupTags,"item-text":"name","item-value":"id","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c('v-chip',{staticClass:"white--text",style:(index > 0 ? 'margin-left: -10px;' : ''),attrs:{"color":_vm.stringToColor(item.name)}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text caption"},[_vm._v(_vm._s(("(+" + (_vm.selectedUserGroupTagIds.length - 2) + " " + (_vm.$t('core.global.others')) + ")")))]):_vm._e()]}}]),model:{value:(_vm.selectedUserGroupTagIds),callback:function ($$v) {_vm.selectedUserGroupTagIds=$$v},expression:"selectedUserGroupTagIds"}}),_c('v-checkbox',{attrs:{"label":"Invite"},model:{value:(_vm.invite),callback:function ($$v) {_vm.invite=$$v},expression:"invite"}})],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":_vm.importing},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),(_vm.step < 2)?_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.users.length},on:{"click":function($event){_vm.step += 1}}},[_vm._v(" "+_vm._s(_vm.$t('core.global.next'))+" ")]):_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.canImport,"loading":_vm.importing},on:{"click":_vm.importUsers}},[_vm._v(" "+_vm._s(_vm.$t('core.action.import'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
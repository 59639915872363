































































import mixins from 'vue-typed-mixins'
import { createTextWithFallback, getTextForUserLanguage } from '@simpl/core/utils/text'
import { UPDATE_CONTENT_PRESET } from '@simpl/cms/graphql'
import { ContentPreset, Tag } from '@simpl/core/types/graphql'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import ContentPresetTags from '@simpl/core/mixins/apollo/ContentPresetTags'
import { unsavedChanges } from '@simpl/core/utils/message'
import { objectHash } from '@simpl/core/utils'

const emptyValue = (): Record<string, any> => ({
  name: '',
  selectedTags: []
})

export default mixins(StringToColor, ContentPresetTags).extend({
  name: 'EditPresetDialog',

  model: {},

  props: {
    value: Boolean,
    edit: Boolean,
    id: [String, Number],
    preselected: Object as () => ContentPreset
  },

  data () {
    return {
      initialValue: emptyValue(),
      editedValue: emptyValue(),

      initialHash: null! as string,
      currentHash: null! as string,

      loading: 0
    }
  },

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean): void {
        this.$emit('input', v)
      }
    },
    originalName (): string | void {
      return getTextForUserLanguage(this.preselected) || ''
    },
    hasChanged (): boolean {
      return this.initialHash !== this.currentHash
    }
  },

  watch: {
    show (v) {
      if (!v) return

      if (this.preselected) {
        this.initialValue = {
          name: getTextForUserLanguage(this.preselected),
          selectedTags: this.preselected.tags
        }

        this.editedValue = { ...this.initialValue }
      } else {
        this.initialValue = this.editedValue = emptyValue()
      }

      this.initialHash = objectHash(this.initialValue)
      this.currentHash = objectHash(this.editedValue)
    },
    editedValue: {
      deep: true,
      handler (v) {
        this.currentHash = objectHash(v)
      }
    }
  },

  methods: {
    getTextForUserLanguage,

    async close () {
      this.show = this.hasChanged ? await unsavedChanges(this) : false
    },
    async save () {
      this.loading += 1

      await this.$apollo.mutate({
        mutation: UPDATE_CONTENT_PRESET,
        variables: {
          data: {
            id: this.preselected.id,
            texts: {
              create: [
                ...createTextWithFallback(this.editedValue.name)
              ]
            },
            tags: {
              sync: this.editedValue.selectedTags.map((tag: Tag) => tag.id)
            }
          }
        }
      })

      this.loading -= 1
      this.show = false

      this.$emit('save')
    }
  }
})

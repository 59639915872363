
























































































































































































































































































import mixins from 'vue-typed-mixins'
import {
  LIST_DOMAINS,
  DELETE_DOMAIN,
  SET_ACTIVE_DOMAIN,
  RESTORE_DOMAIN, ERASE_DOMAIN_WITH_DEPENDENCIES
} from '../graphql'
import { getTextForUserLanguage } from '@simpl/core/utils/text'
import EditDomainDialog from '../components/EditDomainDialog.vue'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import { ACTIONS } from '@simpl/auth/store/consts'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import { Domain, FilterColumn, Plan, Query } from '@simpl/core/types/graphql'
import { DataTableHeader } from '@simpl/core/types/table'
import { TranslateResult } from 'vue-i18n'

type DomainExtended = Domain & {
  name: TranslateResult | string,
  planName: TranslateResult | string,
  planEndDate: string
}

export default mixins(StringToColor,
                      UserSettings('domainList', [
                        'sortBy',
                        'sortDesc',
                        'selectedView',
                        'itemsPerPage'
                      ])).extend({
  name: 'DomainListView',

  components: { EditDomainDialog },

  data: () => ({
    /** Displayed data */
    domains: [],
    loading: 0,

    /** Dialog control flow */
    dialog: false,
    selected: null as Domain | null,

    preset: null,

    /** Search and filter */
    searchValue: '',
    sortBy: [] as any[],
    sortDesc: [] as any[],
    count: 1,
    page: 1,
    itemsPerPage: 10,
    filterBy: [] as FilterColumn[],
    showFilter: false
  }),

  computed: {
    headers (): DataTableHeader[] {
      /** Table view */
      return [{
        text: this.$t('core.global.id'),
        align: 'left',
        value: 'id',
        width: 70,
        sortable: false
      }, {
        text: this.$t('core.global.name'),
        value: 'name',
        sortable: false
      }, {
        text: this.$t('core.global.namespace'),
        value: 'namespace',
        sortable: false
      }, {
        text: this.$t('core.global.paymentPlan'),
        value: 'planName',
        sortable: false
      }, {
        text: this.$t('module.edit.expiresAt'),
        value: 'planEndDate',
        sortable: false
      }, {
        text: this.$t('core.global.paymentStatus'),
        value: 'payment_status',
        sortable: false
      }, {
        text: '',
        value: 'actions',
        class: 'separate-left',
        width: 140,
        sortable: false
      }]
    },
    computedHeaders (): DataTableHeader[] {
      return this.headers.filter(header => !(this.$vuetify.breakpoint.xs && header.value === 'actions'))
    }
  },

  watch: {
    searchValue () {
      this.page = 1
    }
  },
  apollo: {
    domains: {
      query: LIST_DOMAINS,

      variables () {
        return {
          filter: {
            search: {
              query: this.searchValue,
              columns: ['texts:display_name', 'namespace']
            },
            filterBy: this.filterBy
          },
          orderBy: [
            {
              column: 'parent_id',
              order: 'ASC'
            },
            {
              column: 'namespace',
              order: 'ASC'
            }
          ],
          page: this.page,
          first: this.itemsPerPage
        }
      },

      update (result: Query) {
        const { total, currentPage, perPage } = result.domains!.paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage

        return result.domains!.data.map(this.remapEntry)
      },

      error (error: Error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },
  methods: {
    add () {
      this.selected = this.preset
      this.dialog = true
    },
    edit (item: DomainExtended) {
      this.selected = item
      this.dialog = true
    },
    trialIsExpired (date: string) {
      return date ? date.substr(0, 10) < new Date().toISOString().split('T')[0] : false
    },
    getPlanEndDate (planAppliedAt: Date, plan: Plan) {
      if (planAppliedAt && plan) {
        const date = new Date(planAppliedAt)
        date.setMonth(date.getMonth() + plan.payment_interval)
        return date.toISOString().split('T')[0]
      }
      return '-'
    },
    isPlanEndDateNear (planEndDate: string) {
      const date = new Date()
      date.setMonth(date.getMonth() + 1)
      return planEndDate < date.toISOString().split('T')[0]
    },
    isPlanEndDateOver (planEndDate: string) {
      return planEndDate < new Date().toISOString().split('T')[0]
    },
    remapEntry (entry: Domain): DomainExtended {
      return {
        ...entry,
        name: getTextForUserLanguage(entry, 'display_name', false) || entry.identifier,
        planName: (entry.plan) ? getTextForUserLanguage(entry.plan) : '-',
        planEndDate: (entry.plan_applied_at && entry.plan) ? this.getPlanEndDate(entry.plan_applied_at, entry.plan) : '-'
      }
    },
    async changeActiveDomain (domain: DomainExtended) {
      this.loading += 1
      await this.$apollo.mutate({
        mutation: SET_ACTIVE_DOMAIN,
        variables: {
          domain_id: domain.id
        }
      })
      this.loading -= 1
      await this.$store.dispatch(`auth/${ACTIONS.LOGOUT}`)
      const host = window.location.host
      window.location.href = `${window.location.protocol}//${domain.namespace}${host.substr(host.indexOf('.'))}/login`
    },
    async revertDeletion (id: string) {
      this.loading += 1

      await this.$apollo.mutate({
        mutation: RESTORE_DOMAIN,
        variables: {
          id: id
        }
      })

      this.loading -= 1

      await this.$apollo.queries.domains.refetch()

      this.$notification.publish('bottom', {
        message: this.$t('accessControl.domain.domainRestored'),
        color: 'success',
        type: 'success'
      })
    },
    async deleteForever (id: string) {
      const answer = await this.$confirm({
        color: 'error',
        message: this.$t('accessControl.domain.eraseConfirm'),
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.deleteForever'),
          color: 'error',
          answer: true
        }]
      })

      if (!answer) return

      this.loading += 1
      await this.$apollo.mutate({
        mutation: ERASE_DOMAIN_WITH_DEPENDENCIES,
        variables: {
          id
        }
      })
      this.loading -= 1
      await this.$apollo.queries.domains.refetch()
    },
    async remove (id: string, name: string) {
      const answer = await this.$confirm({
        color: 'error',
        message: this.$t('core.message.deleteConfirm', [name]),
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.delete'),
          color: 'error',
          answer: true
        }]
      })

      if (!answer) return

      this.loading += 1
      await this.$apollo.mutate({
        mutation: DELETE_DOMAIN,
        variables: {
          id
        }
      })
      this.loading -= 1
      await this.$apollo.queries.domains.refetch()

      this.$notification.publish('bottom', {
        message: this.$t('accessControl.domain.domainDeleted'),
        color: 'success',
        type: 'success'
      })
    },
    async onSave () {
      this.loading += 1
      await this.$apollo.queries.domains.refetch()
      this.loading -= 1
      this.selected = null
    }
  }
})

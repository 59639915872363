

































































import mixins from 'vue-typed-mixins'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import { TagWithName } from '@simpl/core/types/extended-types'

export default mixins(StringToColor).extend({
  name: 'UserGroupSelectDialog',

  model: {},

  props: {
    value: Boolean,
    userGroupIds: Array as () => string[],
    userGroupTags: Array as () => TagWithName[]
  },

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    selectedUserGroupIds: {
      get (): string[] {
        return this.userGroupIds
      },
      set (v: string[]) {
        this.$emit('update:user-group-ids', v)
      }
    }
  },

  methods: {
    approve () {
      this.show = false
      this.$emit('approve')
    }
  }
})
























































import mixins from 'vue-typed-mixins'
import { createTextWithFallback, getTextForUserLanguage, nameToIdentifier } from '@simpl/core/utils/text'
import { CREATE_TAG, UPDATE_TAG } from '@simpl/access-control/graphql'
import { Tag } from '@simpl/core/types/graphql'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import ContentPresetTags from '@simpl/core/mixins/apollo/ContentPresetTags'

export default mixins(StringToColor, ContentPresetTags).extend({
  name: 'EditPresetTagDialog',

  model: {},

  props: {
    value: Boolean,
    edit: Boolean,
    id: [String, Number],
    preselected: Object as () => Tag
  },

  data () {
    return {
      presetTags: [] as Tag[],
      internalName: '',
      loading: 0
    }
  },

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean): void {
        this.$emit('input', v)
      }
    },
    title (): any {
      return this.edit
        ? this.$t('cms.presets.editTag')
        : this.$t('cms.presets.createTag')
    },
    originalName (): string | void {
      return getTextForUserLanguage(this.preselected, 'display_name', false) || ''
    },
    hasChanged (): boolean {
      return this.originalName !== this.internalName
    },
    identifier (): string {
      return nameToIdentifier(this.internalName as string)
    }
  },

  watch: {
    show (v) {
      if (!v) return

      this.internalName = this.originalName as string
    }
  },

  methods: {
    getMutationData (): Record<string, any> {
      const data = {
        category: {
          connect: this.contentPresetTagCategoryId
        },
        identifier: this.identifier,
        texts: {
          create: [
            ...createTextWithFallback(this.internalName)
          ]
        }
      } as Record<string, any>

      if (this.preselected && this.edit) {
        data.id = this.preselected.id
      }

      return {
        data
      }
    },
    async close () {
      if (this.hasChanged) {
        this.show = await this.$confirm({
          x: 'right',
          y: 'top',
          message: this.$t('core.message.unsavedChanges'),
          buttons: [{
            text: this.$t('core.global.yes'),
            type: 'outlined',
            answer: false
          }, {
            text: this.$t('core.global.no'),
            answer: true
          }]
        })
      } else {
        this.show = false
      }
    },
    async save () {
      this.loading += 1

      await this.$apollo.mutate({
        mutation: this.preselected && this.edit ? UPDATE_TAG : CREATE_TAG,
        variables: this.getMutationData()
      })

      this.loading -= 1
      this.show = false

      this.$emit('save')
    }
  }
})

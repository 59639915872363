var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height content-padding"},[_c('Headline',{staticClass:"headline",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('user.sidebar.pending'))+" ")]),_c('SearchFilterBar',{staticClass:"flex-grow-0 mb-6 mr-0",attrs:{"search-value":_vm.searchValue},on:{"update:searchValue":function($event){_vm.searchValue=$event},"update:search-value":function($event){_vm.searchValue=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('user-create'),expression:"'user-create'"}],staticClass:"ml-4",attrs:{"outlined":"","disabled":!_vm.selectedUsers.length},on:{"click":function($event){_vm.showUserGroupsDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('user.action.approveUsers'))+" ")])]},proxy:true}])}),_c('v-data-table',{attrs:{"loading":_vm.loading > 0,"headers":_vm.computedHeaders,"items":_vm.pendingUsers,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.count,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"multi-sort":""},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header.id",fn:function(){return [_c('v-checkbox',{on:{"change":_vm.selectAll}})]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item.id,"disabled":item.disabled},model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}}),_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[(_vm.$vuetify.breakpoint.xs)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":_vm.$vuetify.theme.dark,"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(!item.is_super)?_c('v-list-item',[_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('user-create'),expression:"'user-create'"}],attrs:{"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(!!item.invitation_sent ? 'mdi-email-outline' : 'mdi-email')+" ")])],1)],1):_vm._e(),_c('v-list-item',[_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('user-create'),expression:"'user-create'"}],attrs:{"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1)],1)],1):_vm._e()],1)]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" "),(item.disabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('accessControl.message.emailNotConfirmed')))])]):_vm._e()]}},{key:"item.languagecode",fn:function(ref){
var item = ref.item;
return [(item.languagecode)?_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.stringToColor(item.languagecode),"small":""}},[_vm._v(" "+_vm._s(_vm.getLanguageTagName(item.languagecode))+" ")]):_vm._e()]}},{key:"item.market",fn:function(ref){
var item = ref.item;
return [(item.market)?_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.stringToColor(_vm.getTextForUserLanguage(item.market)),"small":""}},[_vm._v(" "+_vm._s(_vm.getTextForUserLanguage(item.market))+" ")]):_vm._e()]}},(!_vm.$vuetify.breakpoint.xs)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('user-delete'),expression:"'user-delete'"}],staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.deleteUser(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('user.action.deletePendingUser')))])])],1)]}}:null],null,true)}),_c('UserGroupSelectDialog',{attrs:{"user-group-ids":_vm.selectedUserGroupIds,"user-group-tags":_vm.userGroupTags},on:{"update:userGroupIds":function($event){_vm.selectedUserGroupIds=$event},"update:user-group-ids":function($event){_vm.selectedUserGroupIds=$event},"approve":_vm.approveSelectedUsers},model:{value:(_vm.showUserGroupsDialog),callback:function ($$v) {_vm.showUserGroupsDialog=$$v},expression:"showUserGroupsDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
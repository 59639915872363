



































import mixins from 'vue-typed-mixins'
import UserList from './UserList.vue'
import PendingUsers from './PendingUsers.vue'
import UsersSidebar from '../components/UsersSidebar.vue'
import LanguageTags from '@simpl/core/mixins/apollo/LanguageTags'
import UserGroupTags from '@simpl/core/mixins/apollo/UserGroupTags'
import Roles from '@simpl/core/mixins/apollo/Roles'

export default mixins(LanguageTags, UserGroupTags, Roles).extend({
  name: 'AccessControl',

  components: {
    UserList,
    PendingUsers,
    UsersSidebar
  },

  props: {
    view: {
      type: String,
      default: 'list'
    }
  },

  breadcrumbs () {
    return this.view === 'pending'
      ? [{
        text: 'user.sidebar.pending',
        to: null
      }]
      : []
  },

  computed: {
    views (): Record<string, any> {
      return [{
        key: 'list',
        icon: 'mdi-account-multiple',
        component: UserList
      }, {
        key: 'pending',
        icon: 'mdi-account-multiple-plus',
        component: PendingUsers
      }]
    },
    isRegisterAllowed (): boolean {
      return !!this.$store.state.auth.domain?.properties?.self_registration?.enabled
    }
  }
})

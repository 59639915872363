




































































































































































































import mixins from 'vue-typed-mixins'
import { GET_USER_MINIMAL, USER_TRACKING_HISTORY } from '../graphql'
import { RUN_SESSIONS } from '@simpl/core/graphql'
import { getTextForUserLanguage } from '@simpl/core/utils/text'
import { stringToDate } from '@simpl/core/utils/core'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import { Breadcrumb } from '@simpl/core/plugins/breadcrumbs'
import { DataTableHeader } from '@simpl/core/types/table'
import {
  OrderByClause,
  OrderByRelationClause,
  SortOrder,
  RunUserPivot,
  Query,
  Run,
  User, Module, Session
} from '@simpl/core/types/graphql'
import { TranslateResult } from 'vue-i18n'

type RunTrackingData = RunUserPivot & {
  id: string,
  name: TranslateResult
}

export default mixins(
  StringToColor,
  UserSettings('userHistory', [
    'sortBy',
    'sortDesc',
    'selectedView',
    'itemsPerPage'
  ])).extend({
  name: 'UserHistory',

  props: {
    userId: [Number, String]
  },

  breadcrumbs () {
    const breadcrumbs: Breadcrumb[] = [{
      text: 'user.global.userHistory',
      to: null
    }]

    if (this.user) {
      breadcrumbs.unshift({
        title: this.$t('core.global.fullname', [this.user.firstname, this.user.lastname]),
        to: null
      })
    }

    breadcrumbs.unshift({
      title: this.$t('user.global.headline'),
      to: '/users'
    })

    return breadcrumbs
  },

  data () {
    return {
      user: {} as Partial<User>,
      runs: [] as RunUserPivot[],
      runTrackingData: {} as Record<string, any>,

      /** Search and filter */
      loading: 0,
      searchValue: '',
      sortBy: [] as any[],
      sortDesc: [] as any[],
      count: 1,
      page: 1,
      itemsPerPage: 10,

      expanded: [] as RunTrackingData[],

      relationData: {
        column: 'text',
        relation: 'run.texts',
        conditions: [{
          column: 'languagecode',
          value: this.$store.state.auth.user.languagecode
        }]
      },

      currentLanguage: this.$store.state.auth.user.languagecode
    }
  },

  computed: {
    headers (): DataTableHeader[] {
      /** Table view */
      return [{
        text: this.$t('core.global.training'),
        align: 'left',
        value: 'text'
      }, {
        text: this.$t('core.global.type'),
        value: 'type',
        sortable: false
      }, {
        text: this.$t('core.global.updatedAt'),
        value: 'updated_at'
      }, {
        text: this.$t('core.global.progress'),
        value: 'progress'
      }, {
        text: this.$t('core.global.score'),
        value: 'score'
      }, {
        text: '',
        value: 'data-table-expand'
      }]
    },
    orderBy (): OrderByClause[] {
      if (this.sortBy.length && this.sortBy[0] === 'updated_at') {
        return [{
          column: 'started_at',
          order: this.sortDesc[0] ? SortOrder.Desc : SortOrder.Asc
        }, {
          column: 'completed_at',
          order: this.sortDesc[0] ? SortOrder.Desc : SortOrder.Asc
        }]
      }
      return this.sortBy
        .filter(key => key !== this.relationData.column)
        .map((key, index) => ({
          column: this.sortBy[index],
          order: this.sortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }))
    },
    orderByRelation (): OrderByRelationClause {
      const index = this.sortBy.indexOf(this.relationData.column)
      return index > -1
        ? {
          ...this.relationData,
          order: this.sortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }
        : {}
    }
  },

  watch: {
    expanded (v: RunTrackingData[]) {
      if (!v.length) return

      v.forEach(async expandedRun => {
        if (!expandedRun.run?.modules) {
          const answer = await this.$apollo.query({
            query: RUN_SESSIONS,
            variables: {
              user_id: this.userId,
              run_id: expandedRun.id
            }
          })

          const modules = answer.data.runSessions.filter((session: Session) => !!session.module)

          const remappedModules = modules.map((module: Module) => {
            const copy: any = { ...module }
            copy.name = getTextForUserLanguage(copy.module)
            if (copy.tracking_status) {
              copy.started = stringToDate(copy.tracking_status.started_at)
              copy.completed = stringToDate(copy.tracking_status.completed_at)
              copy.progress = copy.tracking_status?.progress || 0
            }
            return copy
          })

          this.runs.forEach(run => {
            if (run.id === expandedRun.id) {
              this.$set(this.runTrackingData, run.id, remappedModules)
            }
          })
        }
      })
    }
  },

  apollo: {
    runs: {
      query: USER_TRACKING_HISTORY,

      variables () {
        return {
          user_id: this.userId,
          filter: {
            search: {
              query: this.searchValue,
              columns: ['run.texts:display_name']
            },
            filterBy: []
          },
          orderBy: this.orderBy,
          orderByRelation: this.orderByRelation,
          page: this.page,
          first: this.itemsPerPage
        }
      },

      update (result: Query): RunTrackingData[] {
        const { total, currentPage, perPage } = result.userTrackingHistory!.paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage

        return result.userTrackingHistory!.data.map(this.remapEntry)
      },

      loadingKey: 'loading'
    },
    user: {
      query: GET_USER_MINIMAL,

      variables () {
        return {
          id: this.userId
        }
      },

      update (data: Query): Partial<User> | null {
        if (!data.user) {
          this.$router.replace('/404')
          return null
        }

        return data.user
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    remapEntry (entry: RunUserPivot): RunTrackingData {
      const runData = entry.run!

      return {
        id: runData.id,
        ...entry,
        name: getTextForUserLanguage(runData)
      } as RunTrackingData
    }
  }
})


















































import StringToColor from '@simpl/core/mixins/utils/StringToColor'

import mixins from 'vue-typed-mixins'
import { EditableDomain } from '../../types'

export default mixins(StringToColor).extend({
  name: 'DomainSettingsTheme',

  props: {
    domain: Object as () => EditableDomain
    // updates: Object as () => RunEditUpdates
  },

  data () {
    return {
      loading: false,
      errorMessages: ''
    }
  },

  computed: {},

  watch: {
  },

  methods: {
  }
})

































































































































































































































































































































































import mixins from 'vue-typed-mixins'
import { objectHash, getTextForUserLanguage } from '@simpl/core/utils'
import { unsavedChanges } from '@simpl/core/utils/message'
import { filterTagsByCategory, hasTagsFromCategory } from '@simpl/core/utils/tag-filter'
import { UPDATE_USER, CREATE_USER, USER_EXISTS, INVITE, NOTIFY_ALLOCATED } from '../graphql'
import { NewUserInput, Role, Tag, UpdateUserInput, UserExists } from '@simpl/core/types/graphql'
import { UserExtended, RoleWithName, TagWithName, RoleExtended } from '@simpl/core/types/extended-types'
import { TranslateResult } from 'vue-i18n'
import UserQuotaControl from '../mixins/UserQuotaControl'
import ActiveDomainTags from '@simpl/core/mixins/apollo/ActiveDomainTags'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import TagSelectFilter from '@simpl/core/mixins/utils/TagSelectFilter'
import UserUpdateNotifications from '../mixins/UserUpdateNotifications'
import ValidationRules from '@simpl/core/mixins/utils/ValidationRules'
import DomainMarketSelection from '@simpl/core/components/DomainMarketSelection.vue'
import { getLocaleDateTimeFormat } from '@simpl/core/utils/date-time-format'

export type NewUser = {
  active: boolean,
  email: string,
  username: string,
  firstname: string,
  lastname: string,
  languagecode: string,
  // eslint-disable-next-line camelcase
  expires_at: string,
  tags: Tag[],
  roles: Role[]
}

function newUser (): NewUser {
  return {
    active: true,
    email: '',
    username: '',
    firstname: '',
    lastname: '',
    languagecode: '',
    expires_at: null! as string,
    tags: [] as Tag[],
    roles: [] as Role[]
  }
}

export default mixins(
  UserQuotaControl, ActiveDomainTags, StringToColor,
  TagSelectFilter, UserUpdateNotifications, ValidationRules
).extend({
  name: 'EditUserDialog',

  model: {},

  components: { DomainMarketSelection },

  props: {
    value: Boolean,
    selectedUser: Object as () => UserExtended,
    userGroupTags: Array as () => TagWithName[],
    roles: Array as () => RoleWithName[]
  },

  data () {
    return {
      valid: true,

      user: newUser() as NewUser | UserExtended,
      selectedRole: null! as any,
      sendInvitation: false,

      initialHash: objectHash(newUser()) as string,
      currentHash: objectHash(newUser()) as string,

      passwordBySuperuser: '' as string,

      userExists: null! as UserExists,
      errorMessageEmailExists: '' as string | TranslateResult,
      errorMessageUsernameExists: '' as string | TranslateResult,
      loading: false,
      showExpiryDatePicker: false
    }
  },

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    availableRoles (): RoleExtended[] {
      return this.parseRolesQuota(this.roles)
    },
    selectedLanguageTags (): Tag[] {
      return filterTagsByCategory(this.user.tags, 'language')
    },
    selectedUserGroupTags (): Tag[] {
      return filterTagsByCategory(this.user.tags, 'usergroup')
    },
    currentUserMarkets (): Tag[] {
      return filterTagsByCategory(this.$store.state.auth.user.tags, 'market')
    },
    hasChanged (): boolean {
      return this.initialHash !== this.currentHash
    },
    userExistsInOtherDomain (): boolean {
      return this.userExists?.exists && this.userExists.status === 'other-domain'
    },
    canSave (): boolean {
      return this.valid && this.hasChanged
    },
    userExpiresAtFormatted (): string | null {
      return this.user.expires_at ? getLocaleDateTimeFormat(this.user.expires_at) : null
    }
  },

  watch: {
    user: {
      deep: true,
      immediate: true,
      handler (v: UserExtended) {
        this.currentHash = objectHash(v)
      }
    },
    show (v) {
      if (!v) {
        if (this.hasChanged) {
          (this.$refs.form as any).resetValidation()
        }
        this.selectedRole = null!
        this.sendInvitation = false
        this.userExists = null!
        return
      }

      if (this.selectedUser) {
        this.user = { ...this.selectedUser }
        this.selectedRole = this.user.roles[0]
        this.initialHash = objectHash(this.user)
      } else {
        this.user = newUser()
        this.initialHash = objectHash(newUser())
      }

      if (!this.selectedUser && this.activeDomainMarketTags.length === 1) {
        this.user.tags.push(this.activeDomainMarketTags[0])
      }
    },
    selectedRole (v) {
      this.user.roles = [v]
    }
  },

  methods: {
    getTextForUserLanguage,

    requiredLanguage () {
      return hasTagsFromCategory(this.user.tags, 'language') || this.$t('core.global.required')
    },
    requiredUsergroup () {
      return !this.userGroupTags.length || hasTagsFromCategory(this.user.tags, 'usergroup') ||
        this.$t('core.global.required')
    },
    requiredMarket () {
      return !this.activeDomainMarketTags.length || hasTagsFromCategory(this.user.tags, 'market') ||
        this.$t('core.global.required')
    },

    async emailExists (): Promise<void> {
      if (!this.hasChanged ||
        !this.user.email ||
        this.selectedUser?.email?.toLowerCase() === this.user.email.toLowerCase()) return

      const exists = await this.$apollo.query({
        query: USER_EXISTS,
        variables: {
          type: 'email',
          query: this.user.email.toLowerCase()
        }
      })

      this.userExists = exists.data.userExists
      if (this.userExists?.exists) this.handleUserAlreadyExists('email')
    },
    async usernameExists (): Promise<void> {
      if ((this.selectedUser && this.selectedUser.username === this.user.username) || !this.user.username) return

      const exists = await this.$apollo.query({
        query: USER_EXISTS,
        variables: {
          type: 'username',
          query: this.user.username
        }
      })

      this.userExists = exists.data.userExists
      if (this.userExists?.exists) this.handleUserAlreadyExists('username')
    },
    handleUserAlreadyExists (type: string) {
      if (this.userExistsInOtherDomain && !this.selectedUser && this.$store.state.auth.user.is_super) {
        Object.keys(this.user).forEach(key => {
          (this.user as any)[key] = (this.userExists.user as any)[key]
        })
        this.selectedRole = this.userExists.user?.roles[0]
      } else {
        const statusMsg = this.userExists.status!.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
          return g1.toUpperCase() + g2.toLowerCase()
        }).replace('-', '')

        if (type === 'email') {
          this.errorMessageEmailExists = this.$t(`user.error.alreadyExists${statusMsg}`)
        }

        if (type === 'username') {
          this.errorMessageUsernameExists = this.$t(`user.error.alreadyExists${statusMsg}`)
        }
      }
    },

    async disableTfa () {
      this.loading = true
      await this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: {
          data: {
            id: (this.user! as UserExtended).id,
            tfa_enabled: false
          }
        }
      })

      this.loading = false
      this.show = false
      this.$emit('save')
    },

    getUserMutationData (): { data: NewUserInput | UpdateUserInput } {
      const data = {
        active: this.user.active,
        email: null,
        username: null,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        languagecode: this.user.languagecode,
        expires_at: this.user.expires_at,
        roles: {
          sync: [this.selectedRole.id]
        },
        tags: {
          sync: this.user.tags.map((tag: Tag) => tag.id)
        }
      } as NewUserInput | UpdateUserInput

      if (this.user.email) data.email = this.user.email!.toLowerCase()
      if (this.user.username) data.username = this.user.username
      if (this.selectedUser) (data as UpdateUserInput).id = this.selectedUser.id

      if (this.passwordBySuperuser && this.$store.state.auth.user.is_super) {
        data.password = this.passwordBySuperuser
      }

      if (!(this.user as UserExtended).id && this.userExists?.user?.id) {
        (data as UpdateUserInput).id = this.userExists.user.id
      }

      return { data }
    },

    async save () {
      this.loading = true

      try {
        const answer = await this.$apollo.mutate({
          mutation: this.selectedUser ? UPDATE_USER : CREATE_USER,
          variables: this.getUserMutationData()
        })

        if (!this.selectedUser && this.sendInvitation) {
          const userId = answer.data[this.selectedUser ? 'updateUser' : 'createUser'].id

          await this.$apollo.mutate({
            mutation: INVITE,
            variables: {
              data: { user_id: userId }
            }
          })
          this.notifyUserInvited()
        }

        this.notifyUserCreatedOrUpdated(!this.selectedUser)
      } catch (e) {
        this.$notification.publish('bottom', {
          message: this.$t(e.message),
          type: 'error',
          color: 'error'
        })
      }

      this.loading = false
      this.show = false
      this.$emit('save')
    },
    async join () {
      this.loading = true

      const variables = this.getUserMutationData()
      ;(variables.data as UpdateUserInput).domains = {
        connect: [this.$store.state.auth.user.active_domain.id]
      }

      await this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: variables
      })

      this.notifyUserCreatedOrUpdated()

      if (this.sendInvitation) {
        await this.$apollo.mutate({
          mutation: NOTIFY_ALLOCATED,
          variables: {
            data: { user_id: this.userExists!.user!.id }
          }
        })
        this.notifyUserNotified()
      }

      this.loading = false
      this.show = false
      this.$emit('save')
    },
    async close () {
      this.show = this.hasChanged ? await unsavedChanges(this) : false
    },
    generatePassword (): string {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 12; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    reformatExpiryDate () {
      this.user.expires_at = `${this.user.expires_at} 00:00:00`
    }
  }
})

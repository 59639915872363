


































































import Vue from 'vue'

export default Vue.extend({
  name: 'DomainSettingsSidebar',

  model: {},

  props: {
    id: [Number, String],
    views: Array,
    value: Boolean
  },

  data: () => ({
    mini: false
  }),

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    }
  },

  created () {
    this.mini = this.$vuetify.breakpoint.xs

    if (!this.show && !this.mini) this.show = true
  }
})

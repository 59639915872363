import Vue from 'vue'
import { DomainQuotaItem, DomainQuotas, Query, Role } from '@simpl/core/types/graphql'
import { DOMAIN_QUOTAS } from '../graphql'
import { TranslateResult } from 'vue-i18n'
import { getTextForUserLanguage } from '@simpl/core/utils'
import { RoleExtended } from '@simpl/core/types/extended-types'

export default Vue.extend({
  name: 'UserQuotaControl',

  data: () => ({
    domainQuotas: {} as DomainQuotas,
    count: 0
  }),

  computed: {
    userLimit (): number {
      return this.$store.state.auth.domain?.user_limit
    },
    userCapacity (): number {
      return this.userLimit > 0
        ? this.$store.state.auth.domain.user_limit - this.count
        : -1
    },
    userLimitHint (): TranslateResult | null {
      return this.userLimit > 0
        ? this.$t('user.message.availableUsers', {
          available: this.userCapacity,
          limit: this.userLimit
        })
        : null
    },
    userLimitCritical (): boolean {
      return this.userLimit > 0 ? (this.count / this.userLimit) >= 0.9 || this.userCapacity <= 3 : false
    },
    allQuotasExhausted (): boolean {
      let exhaustedRolesCount = 0
      this.domainQuotas?.roles?.forEach((role: DomainQuotaItem) => {
        if (role.available === 0 && role.quota !== -1) {
          exhaustedRolesCount++
        }
      })

      return exhaustedRolesCount === this.domainQuotas?.roles?.length
    },
    noCapacity (): boolean {
      return this.allQuotasExhausted || this.userCapacity === 0
    }
  },

  apollo: {
    domainQuotas: {
      query: DOMAIN_QUOTAS,

      fetchPolicy: 'cache-and-network',

      update (result: Query): DomainQuotas {
        return result.domainQuotas
      },

      error (error: Error): void {
        console.error(error)
      }
    }
  },

  methods: {
    parseRolesQuota (roles: Role[]): RoleExtended[] {
      return roles.map((entry: Role) => {
        const filteredQuotas = this.domainQuotas?.roles?.filter((role: DomainQuotaItem) => role.type === entry.identifier)
        let quotaInfo = ''
        let isDisabled = false
        if (filteredQuotas?.length && filteredQuotas[0].quota !== -1) {
          quotaInfo = ` (${filteredQuotas[0].available}/${filteredQuotas[0].quota})`
          isDisabled = filteredQuotas[0].available === 0
        }
        return {
          ...entry,
          disabled: isDisabled,
          name: getTextForUserLanguage(entry),
          nameWithQuota: getTextForUserLanguage(entry) + quotaInfo
        }
      })
    }
  }
})

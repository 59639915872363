





















































































































































































































































































































































































import Vue from 'vue'
import { hasOwn, objectHash } from '@simpl/core/utils/core'
import { createTextWithFallback, getTextForUserLanguage, nameToIdentifier } from '@simpl/core/utils/text'

import { CREATE_DOMAIN, LIST_FEATURES, LIST_PLANS, LIST_USERS, SHOW_DOMAIN, UPDATE_DOMAIN } from '../graphql'
import { Query, User, Domain, Feature, Run, Plan } from '@simpl/core/types/graphql'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import { LIST_RUNS_SHARED_WITH } from '@simpl/base-management/runs/graphql'

type EditableDomain = Domain & {
  imprint?: string,
  disclaimer?: string
}

export type DomainEditUpdates = {
  lang: Record<string, DomainEditUpdates>
  name: string,
  [key: string]: any
}

const RE_URL = new RegExp('^(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$', 'i')

export default Vue.extend({

  mixins: [
    StringToColor
  ],
  props: {
    value: Boolean,
    id: [String, Number],
    selectedDomainId: [String, Number],
    existingDomains: Array
  },

  data () {
    const defaultInput = {
      parent_id: null! as number,
      identifier: '',
      namespace: '',
      markets: false,
      plan: null! as Plan,
      plan_applied_at: null! as string,
      payment_status: '-',
      payment_type: '-',
      user_limit: 0,
      trial_ends_at: null! as string,
      imprint: '',
      disclaimer: '',
      supportUsers: [],
      features: []
    } as Record<string, any>

    const paymentStatuses = [
      {
        key: '-',
        value: '-'
      }, {
        key: 'demo',
        value: this.$t('core.paymentStatus.demo')
      }, {
        key: 'demo-expired',
        value: this.$t('core.paymentStatus.demo-expired')
      }, {
        key: 'pending',
        value: this.$t('core.paymentStatus.pending')
      }, {
        key: 'completed',
        value: this.$t('core.paymentStatus.completed')
      }, {
        key: 'expired',
        value: this.$t('core.paymentStatus.expired')
      }
    ]

    const paymentTypes = [
      {
        key: '-',
        value: '-'
      }, {
        key: 'invoice',
        value: this.$t('core.paymentTypes.invoice')
      }, {
        key: 'stripe',
        value: this.$t('core.paymentTypes.stripe')
      }
    ]

    return {
      /** Displayed data **/
      loading: false,
      errorMessages: '',
      namespaceValid: false,
      namespaceUnique: false,

      imprintUrl: false,
      disclaimerUrl: false,

      name: '',
      namespace: '',

      showDatePickerTrial: false,
      showDatePickerPlan: false,

      /** Input data **/
      input: defaultInput,
      defaultInput: defaultInput,

      tickLabels: ['Off', 30, 60, 90, 120],

      userLimits: [0, 50, 200, 500, 1000],

      existingUsers: [] as User[],

      features: [] as Feature[],

      plans: [] as Plan[],

      lastPlan: null! as Plan,

      shareableRuns: [] as Run[],

      runsShared: [] as String[],

      paymentTypes: paymentTypes,
      paymentStatuses: paymentStatuses,

      updates: { lang: {} } as DomainEditUpdates,

      initialHash: null! as string,
      currentHash: null! as string,

      urlPattern: new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'),

      /** Input control **/
      checking: false,
      rules: {
        url: (value: string) => {
          return RE_URL.test(value) || this.$t('module.error.invalidUrl')
        },
        email: (value: string) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('simplAuth.error.invalidMail')
        },
        namespaceValid: (value: string) => {
          const pattern = /^(?:[a-zA-Z]\d*(?:-[a-zA-Z])?)+$/i
          this.namespaceValid = pattern.test(value)
          return this.namespaceValid || this.$t('accessControl.error.invalidNamespace')
        },
        namespaceExists: (value: string) => {
          this.namespaceUnique = this.existingDomains.filter(domain => domain.namespace === value).length === 0 || value === this.namespace
          return this.namespaceUnique || this.$t('accessControl.error.existingNamespace')
        },
        required: (value: string) => !!value || this.$t('core.global.required')
      },
      selectedPlan: null! as Plan,
      addThemeFeature: false
    }
  },

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean): void {
        this.$emit('input', v)
      }
    },
    canSave (): boolean {
      return !!(
        this.name &&
        this.namespaceValid &&
        this.namespaceUnique &&
        this.featureIdsByPlan.length &&
        this.hasChanged
      )
    },
    hasChanged (): boolean {
      return this.initialHash !== this.currentHash
    },
    trialDate: {
      get (): string|null {
        return (this.input.trial_ends_at) ? this.input.trial_ends_at.substr(0, 10) : null
      },
      set (v: string): void {
        this.input.trial_ends_at = (v) ? v + ' 23:59:59' : null
      }
    },
    featureIdsByPlan (): any {
      if (!this.features || !this.selectedPlan) return []
      return this.features.filter((feature: any) => this.selectedPlan!.feature_set!
        .find((value: string) => value === feature.identifier))
        .map((feature: Feature) => {
          return feature.id
        })
    }
  },

  watch: {
    input: {
      deep: true,
      handler (v: object): void {
        this.currentHash = objectHash({ ...v, name: this.name, shared: this.runsShared, addThemeFeature: this.addThemeFeature })
      }
    },
    name: {
      handler (v: string): void {
        this.currentHash = objectHash({ ...this.input, name: v, shared: this.runsShared, addThemeFeature: this.addThemeFeature })
      }
    },
    runsShared: {
      handler (v: string): void {
        this.currentHash = objectHash({ ...this.input, name: this.name, shared: v, addThemeFeature: this.addThemeFeature })
      }
    },
    addThemeFeature: {
      handler (v: boolean): void {
        this.currentHash = objectHash({ ...this.input, name: this.name, shared: this.runsShared, addThemeFeature: v })
      }
    },
    selectedPlan (v) {
      if (v) {
        if (!this.lastPlan || this.lastPlan.identifier !== this.selectedPlan.identifier) {
          this.input.plan_applied_at = new Date().toISOString().substr(0, 10)
        }
      }
    },
    async show (v: boolean): Promise<void> {
      if (!v) return

      if (this.selectedDomainId) {
        this.loading = true
        const result = await this.$apollo.query({
          query: SHOW_DOMAIN,
          variables: {
            id: this.selectedDomainId
          }
        })
        this.input = JSON.parse(JSON.stringify(result.data.domain))
        if (result.data.domain.parent) {
          this.input.parent_id = result.data.domain.parent.id
        }
        this.name = getTextForUserLanguage(this.input)
        this.namespace = this.input.namespace
        this.input.imprint = getTextForUserLanguage(this.input, 'imprint', false)
        this.input.disclaimer = getTextForUserLanguage(this.input, 'disclaimer', false)

        result.data.domain.shared_resources.forEach((resource: any) => {
          this.runsShared.push(resource.id)
        })

        this.selectedPlan = (this.input.plan) ? this.plans.filter((plan: Plan) => plan.identifier === this.input.plan.identifier)[0] : null! as Plan
        this.lastPlan = this.input.plan
        this.addThemeFeature = result.data.domain.features.filter((f: Feature) => f.identifier === 'theme').length

        this.loading = false
      } else {
        this.input = { ...this.defaultInput }
        this.name = ''
        this.namespace = ''
        this.selectedPlan = null! as Plan
        this.addThemeFeature = false
      }

      this.initialHash = this.currentHash = objectHash({ ...this.input, name: this.name, shared: this.runsShared, addThemeFeature: this.addThemeFeature })

      this.$nextTick(() => {
        ;(this.$refs.form as any).resetValidation()
        this.errorMessages = ''

        this.imprintUrl = this.urlPattern.test(this.input.imprint)
        this.disclaimerUrl = this.urlPattern.test(this.input.disclaimer)
      })
    }
  },

  apollo: {
    existingUsers: {
      query: LIST_USERS,

      variables: {
        first: 9999,
        filter: {
          filterBy: []
        }
      },

      update (result: Query): string[] {
        const users = result.users!

        return users.data.map(user => user!.email!)
      },

      error (error: Error): void {
        console.error(error)
      }
    },
    features: {
      query: LIST_FEATURES,

      variables: {
        first: 9999,
        filter: {
          filterBy: []
        }
      },

      update (result: Query): Feature[] {
        const features = JSON.parse(JSON.stringify(result.features!.data))
        features.forEach((feature: any) => {
          feature.name = getTextForUserLanguage(feature)
        })
        return features
      },

      error (error: Error): void {
        console.error(error)
      }
    },
    shareableRuns: {
      query: LIST_RUNS_SHARED_WITH,

      variables: {
        first: 9999,
        filter: {
          filterBy: [{
            name: 'shareable',
            values: [true]
          }]
        }
      },

      update (result: Query): Run[] {
        const shareableRuns = JSON.parse(JSON.stringify(result.runs!.data))
        shareableRuns.forEach((run: any) => {
          run.name = getTextForUserLanguage(run)
        })
        return shareableRuns
      },

      error (error: Error): void {
        console.error(error)
      }
    },
    plans: {
      query: LIST_PLANS,

      variables () {
        return {
          filter: {
            filterBy: [{
              name: 'head',
              values: [true]
            }]
          },
          orderBy: [{
            column: 'sorting',
            order: 'ASC'
          }],
          page: 1,
          first: 999
        }
      },

      update (result: any) {
        const { total, currentPage, perPage } = result.plans && result.plans.paginatorInfo
        return result.plans.data.map(this.remapPlanEntry)
      },

      error (error: Error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    configureFeatures () {
      this.input.plan = this.selectedPlan
    },
    parentChanged () {
      this.currentHash = objectHash({ ...this.input, name: this.name, shared: this.runsShared })
    },
    async updateSupportUsers () {
      if (this.input.properties.notifications.support.length > 0) {
        const lastItem = this.input.properties.notifications.support[this.input.properties.notifications.support.length - 1]
        const result = this.rules.email(lastItem)
        if (result !== true) {
          this.input.properties.notifications.support.splice(-1, 1)

          this.show = await this.$confirm({
            message: this.$t('simplAuth.error.invalidMail'),
            buttons: [{
              text: this.$t('core.global.ok'),
              type: 'outlined',
              answer: true
            }]
          })
        }
      }
    },
    getUserMutationData (): object {
      const data = {
        parent_id: this.input.parent_id,
        identifier: nameToIdentifier(this.name),
        namespace: this.input.namespace,
        markets: this.input.markets,
        payment_type: this.input.payment_type,
        payment_status: this.input.payment_status,
        user_limit: this.input.user_limit,
        trial_ends_at: this.input.trial_ends_at,
        plan_applied_at: this.input.plan_applied_at,
        texts: {
          create: []
        }
      } as Record<string, any>

      if (this.selectedDomainId) {
        data.id = this.selectedDomainId
        data.features = {
          sync: this.featureIdsByPlan
        }
        if (this.addThemeFeature) {
          data.features.sync.push(this.features.filter((f:Feature) => f.identifier === 'theme')[0].id)
        }
      } else {
        data.features = {
          connect: this.featureIdsByPlan
        }
        if (this.addThemeFeature) {
          data.features.connect.push(this.features.filter((f:Feature) => f.identifier === 'theme')[0].id)
        }
      }

      if (this.selectedPlan) {
        data.plan = {
          connect: this.selectedPlan.id
        }
      }

      data.shared_resources = {
        sync: this.runsShared
      }

      data.texts!.create!.push(
        ...createTextWithFallback(this.name)
      )

      if (this.input.imprint) {
        data.texts!.create!.push(
          ...createTextWithFallback(this.input.imprint, this.input, 'imprint')
        )
      }

      if (this.input.disclaimer) {
        data.texts!.create!.push(
          ...createTextWithFallback(this.input.disclaimer, this.input, 'disclaimer')
        )
      }
      return { data }
    },
    async close (): Promise<void> {
      if (this.hasChanged) {
        this.show = await this.$confirm({
          message: this.$t('core.message.unsavedChanges'),
          buttons: [{
            text: this.$t('core.global.yes'),
            type: 'outlined',
            answer: false
          }, {
            text: this.$t('core.global.no'),
            answer: true
          }]
        })
      } else {
        this.show = false
      }
    },
    async save (): Promise<void> {
      this.loading = true

      await this.$apollo.mutate({
        mutation: this.selectedDomainId ? UPDATE_DOMAIN : CREATE_DOMAIN,
        variables: this.getUserMutationData()
      })

      this.$notification.publish('bottom', {
        message: this.$t(`accessControl.domain.${this.selectedDomainId ? 'edited' : 'created'}`),
        type: 'success',
        color: 'success'
      })

      this.loading = false
      this.show = false

      this.$emit('save')
    },
    remapPlanEntry (entry: Plan) {
      return {
        ...entry,
        name: getTextForUserLanguage(entry, 'display_name') || entry.identifier
      }
    },
    getPlanEndDate (planAppliedAt: string, plan: Plan): string {
      if (planAppliedAt && plan) {
        const date = new Date(planAppliedAt)
        date.setMonth(date.getMonth() + plan.payment_interval)
        return date.toISOString().split('T')[0]
      }
      return '-'
    }
  }
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height content-padding"},[_c('Headline',{staticClass:"headline",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('accessControl.global.rolesHeadline'))+" ")]),_c('SearchFilterBar',{staticClass:"flex-grow-0 mb-6 mr-1",attrs:{"search-value":_vm.searchValue},on:{"update:searchValue":function($event){_vm.searchValue=$event},"update:search-value":function($event){_vm.searchValue=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('role-create'),expression:"'role-create'"}],staticClass:"ml-4",attrs:{"color":"primary","fab":"","small":""},on:{"click":_vm.add}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('accessControl.action.createRole')))])])]},proxy:true}])}),_c('v-data-table',{attrs:{"loading":_vm.loading > 0 || _vm.loadingPermissions > 0,"headers":_vm.computedHeaders,"items":_vm.roles,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.count,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('div',[_vm._v(_vm._s(item.id))]),(_vm.$vuetify.breakpoint.xs)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":_vm.$vuetify.theme.dark,"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('role-create'),expression:"'role-create'"}],attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1),_c('v-list-item',[_c('v-btn',{attrs:{"disabled":!_vm.$store.state.auth.user.is_super && item.parent === null,"small":"","icon":""},on:{"click":function($event){return _vm.remove(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1),_c('v-list-item',[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.duplicate(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)],1)],1)],1):_vm._e()],1)]}},{key:"item.permissions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{ 'justify-start px-0': _vm.$vuetify.breakpoint.xs },attrs:{"text":"","small":""}},on),[_vm._v(" "+_vm._s(item.permissions.length)+" ")])]}}],null,true)},[(item.permissions.length)?_c('v-card',{staticClass:"pa-1",attrs:{"max-width":"300"}},_vm._l((item.permissionMap),function(data,group){return _c('v-chip',{key:group,staticClass:"pl-0 pr-2 ma-1 access-control--permissions",attrs:{"small":"","color":((_vm.stringToColor(group)) + " darken-3")}},[_c('div',{staticClass:"access-control--permissions__progress",class:((_vm.stringToColor(group)) + " darken-2"),style:({ width: _vm.permissionsPerType[group] ? ((20 + data.count / _vm.permissionsPerType[group] * 80) + "%") : 0 })}),(data.count < _vm.permissionsPerType[group])?_c('v-avatar',{staticClass:"white--text",attrs:{"color":((_vm.stringToColor(group)) + " darken-4")}},[_vm._v(" "+_vm._s(data.count)+" ")]):_vm._e(),_c('span',{staticClass:"ml-2 white--text"},[_vm._v(_vm._s(group))])],1)}),1):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('role-create'),expression:"'role-create'"}],staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.edit(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('accessControl.action.editRole')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"disabled":!_vm.$store.state.auth.user.is_super && item.parent === null,"small":"","icon":""},on:{"click":function($event){return _vm.remove(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('accessControl.action.deleteRole')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.duplicate(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('accessControl.action.duplicateRole')))])])],1)]}}])}),_c('EditRoleDialog',{attrs:{"preset":_vm.selected,"edit":_vm.editing,"roles":_vm.roles,"permissions":_vm.permissions},on:{"save":_vm.refetch},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
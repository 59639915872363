





































































































import mixins from 'vue-typed-mixins'
import { EditableDomain } from '../../types'
import { LIST_SUPPORT_NOTIFIABLES, NAMESPACE_EXISTS } from '../../graphql'
import { Query, User } from '@simpl/core/types/graphql'
import { TranslateResult } from 'vue-i18n'
import { DomainEditUpdates } from './DomainSettings.vue'
import ValidationRules from '@simpl/core/mixins/utils/ValidationRules'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'

export default mixins(StringToColor, ValidationRules).extend({
  name: 'DomainSettingsBasic',

  props: {
    domain: Object as () => EditableDomain,
    updates: Object as () => DomainEditUpdates
  },

  data () {
    return {
      loading: false,
      valid: false,
      errorMessages: '' as string | TranslateResult,
      nsErrorMessage: '' as string | TranslateResult,
      namespaceValid: true,
      namespaceUnique: true,
      existingUsers: [] as User[]
    }
  },

  apollo: {
    existingUsers: {
      query: LIST_SUPPORT_NOTIFIABLES,

      variables: {
        first: 9999,
        filter: {
          filterBy: []
        }
      },

      update (result: Query): string[] {
        const users = result.supportNotifiables!
        return users.data.map(user => user!.email!)
      },

      error (error: Error): void {
        console.error(error)
      }
    }
  },

  computed: {
    inputsValid (): boolean {
      return this.valid && this.namespaceUnique
    },
    cmsMaxWidthValues (): any[] {
      return [
        { name: '-', value: undefined },
        { name: '900 Pixel', value: '900px' },
        { name: '1024 Pixel', value: '1024px' },
        { name: '1280 Pixel', value: '1280px' },
        { name: '1600 Pixel', value: '1600px' },
        { name: '1920 Pixel', value: '1920px' }
      ]
    }
  },

  watch: {
    inputsValid (v) {
      this.$emit('formValidated', v)
    }
  },

  methods: {
    async nsExists (): Promise<void> {
      const result = await this.$apollo.query({
        query: NAMESPACE_EXISTS,
        variables: {
          query: this.domain.namespace
        }
      })

      this.nsErrorMessage = (result!.data.namespaceExists && this.domain.namespace !== this.$store.state.auth.domain.namespace)
        ? this.$t('accessControl.error.existingNamespace')
        : ''
      this.namespaceUnique = !this.nsErrorMessage.length
    },
    async checkSupportUserValidity () {
      if (this.domain.properties.notifications.support.length > 0) {
        const lastItem = this.domain.properties.notifications.support[this.domain.properties.notifications.support.length - 1]
        const result = this.rules.email(lastItem)
        if (result !== true) {
          this.domain.properties.notifications.support.splice(-1, 1)

          await this.$confirm({
            message: this.$t('simplAuth.error.invalidMail'),
            buttons: [{
              text: this.$t('core.global.ok'),
              type: 'outlined',
              answer: true
            }]
          })
        }
      }
    }
  }
})






























































































































































































































































import { getTextForUserLanguage } from '@simpl/core/utils/text'
import mixins from 'vue-typed-mixins'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import { ContentPreset, Query } from '@simpl/core/types/graphql'
import EditPresetDialog from '../components/dialogs/EditPresetDialog.vue'
import ScreenPreview from '../components/ScreenPreview.vue'
import { GET_CONTENT_PRESETS, DELETE_CONTENT_PRESET } from '../graphql'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import ExportContent from '@simpl/core/mixins/utils/ImportExportContent'
import ImportContentDialog from '@simpl/core/components/dialogs/ImportContentDialog.vue'
import { DataTableHeader } from '@simpl/core/types/table'
import PresetTile from '../components/PresetTile.vue'

export default mixins(StringToColor, ExportContent, UserSettings('cmsPresetListView', [
  'sortBy',
  'sortDesc',
  'itemsPerPage',
  'selectedView'
])).extend({
  name: 'CMSPresetList',

  components: {
    PresetTile,
    EditPresetDialog,
    ScreenPreview,
    ImportContentDialog
  },

  data () {
    return {
      /** Displayed data */
      contentPresets: [] as ContentPreset[],
      loading: 0,

      /** Dialog control flow */
      dialog: false,
      selected: null! as ContentPreset,
      editing: false,

      /** Search and filter */
      searchValue: '',
      sortBy: [],
      sortDesc: [],
      count: 1,
      page: 1,
      itemsPerPage: 10,

      showPresetPreview: false,
      selectedView: 'list',

      showImportContentDialog: false
    }
  },

  computed: {
    headers (): DataTableHeader[] {
      /** Table view */
      return [{
        text: this.$t('core.global.id'),
        align: 'left',
        value: 'id',
        width: 70
      }, {
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('cms.presets.tags'),
        value: 'tags',
        sortable: false
      }, {
        text: '',
        value: 'actions',
        width: 140,
        class: 'separate-left',
        sortable: false
      }]
    },
    computedHeaders (): Record<string, any>[] {
      return this.headers.filter(header => !(this.$vuetify.breakpoint.xs && header.value === 'actions'))
    },
    presets (): Record<string, any> {
      return this.contentPresets.map((preset: ContentPreset) => ({
        ...preset,
        children: JSON.parse(preset.data as string)
      }))
    }
  },

  apollo: {
    contentPresets: {
      query: GET_CONTENT_PRESETS,

      variables (): Record<string, any> {
        return {
          filter: {
            search: {
              query: this.searchValue,
              columns: ['texts:display_name']
            },
            filterBy: []
          },
          orderBy: this.sortBy.map((key, index) => ({
            field: this.sortBy[index],
            order: this.sortDesc[index] ? 'DESC' : 'ASC'
          })),
          page: this.page,
          first: this.itemsPerPage
        }
      },

      update (result: Query): ContentPreset[] {
        const { total, currentPage, perPage } = result.contentPresets!.paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage

        return result.contentPresets!.data
      },

      error (error: Error): void {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    getTextForUserLanguage,

    refetch () {
      this.$apollo.queries.contentPresets.refetch()
    },
    edit (preset: ContentPreset) {
      this.selected = preset
      this.editing = true
      this.dialog = true
    },
    showPreview (preset: ContentPreset) {
      this.showPresetPreview = true
      this.selected = preset
    },
    async remove (presetId: string) {
      const answer = await this.$confirm({
        color: 'error',
        message: this.$t('core.message.unsavedChanges'),
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.delete'),
          color: 'error',
          answer: true
        }]
      })

      if (!answer) return

      this.loading += 1
      await this.$apollo.mutate({
        mutation: DELETE_CONTENT_PRESET,
        variables: {
          id: presetId
        }
      })

      this.loading -= 1

      this.refetch()
    },
    onImport () {
      this.refetch()
    }

  }
})
























































































































































































































import mixins from 'vue-typed-mixins'
import { getTextForUserLanguage } from '@simpl/core/utils/text'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import { DELETE_ROLE, LIST_ROLES } from '../graphql'
import EditRoleDialog from '@simpl/access-control/components/EditRoleDialog.vue'
import { Permission, Query, Role } from '@simpl/core/types/graphql'

export default mixins(StringToColor).extend({
  name: 'AccessControlListView',

  components: {
    EditRoleDialog
  },

  props: {
    permissions: Array as () => Permission[],
    loadingPermissions: Number
  },

  data () {
    return {
      /** Displayed data */
      roles: [] as Role[],
      loading: 0,

      /** Dialog control flow */
      dialog: false,
      selected: null! as Role,
      editing: false,

      /** Search and filter */
      searchValue: '',
      sortBy: [] as any[],
      sortDesc: [] as any[],
      count: 1,
      page: 1,
      itemsPerPage: 10
    }
  },

  computed: {
    headers (): Record<string, any>[] {
      return [{
        text: this.$t('core.global.id'),
        align: 'left',
        value: 'id',
        width: 70
      }, {
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('core.global.identifier'),
        value: 'identifier'
      }, {
        text: this.$t('core.global.permissions'),
        value: 'permissions',
        align: 'right',
        sortable: false
      }, {
        text: '',
        value: 'actions',
        width: 140,
        class: 'separate-left',
        sortable: false
      }]
    },
    computedHeaders (): Record<string, any>[] {
      return this.headers.filter(header => !(this.$vuetify.breakpoint.xs && header.value === 'actions'))
    },
    permissionsPerType (): Record<string, number> {
      return this.permissions.reduce((acc: Record<string, number>, p: any) => {
        const [type] = p.ability.split('-')
        if (!(type in acc)) acc[type] = 0
        acc[type] += 1
        return acc
      }, {})
    }
  },

  apollo: {
    roles: {
      query: LIST_ROLES,

      skip () {
        return !this.permissions?.length
      },

      variables () {
        return {
          filter: {
            search: {
              query: this.searchValue,
              columns: ['texts:display_name']
            },
            filterBy: []
          },
          orderBy: this.sortBy.map((key, index) => ({
            column: this.sortBy[index],
            order: this.sortDesc[index] ? 'DESC' : 'ASC'
          })),
          page: this.page,
          first: this.itemsPerPage
        }
      },

      update (result: Query) {
        const { total, currentPage, perPage } = result.roles!.paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage

        return result.roles!.data.slice().map(this.remapEntry)
      },

      error (error: Error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    remapEntry (entry: Role) {
      return {
        ...entry,
        permissionCount: entry.permissions.length,
        permissionMap: entry.permissions.reduce((acc: any, v) => {
          const group = v.ability.split('-')[0]
          acc[group] = acc[group] || { count: 0 }
          acc[group].count += 1
          return acc
        }, {}),
        name: getTextForUserLanguage(entry)
      }
    },
    async refetch () {
      await this.$apollo.queries.roles.refetch()
    },
    add () {
      this.selected = null!
      this.editing = false
      this.dialog = true
    },
    edit (item: Role) {
      this.selected = item
      this.editing = true
      this.dialog = true
    },
    duplicate (item: Role) {
      this.selected = item
      this.editing = false
      this.dialog = true
    },
    async remove (id: string) {
      const answer = await this.$confirm({
        color: 'error',
        message: 'Are you sure? This action cannot be undone.',
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.delete'),
          color: 'error',
          answer: true
        }]
      })

      if (!answer) return

      this.loading += 1
      await this.$apollo.mutate({
        mutation: DELETE_ROLE,
        variables: {
          id
        }
      })

      this.loading -= 1

      await this.refetch()
    }
  }
})

























































































































































































































































import mixins from 'vue-typed-mixins'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import { EditableDomain } from '../../types'
import { DomainQuotas, Query, User } from '@simpl/core/types/graphql'
import { DOMAIN_QUOTAS, LIST_USERS_MINIMAL } from '../../graphql'
import { getTextForUserLanguage, stringToDate } from '@simpl/core/utils'
import { TranslateResult } from 'vue-i18n'

export default mixins(StringToColor).extend({
  name: 'DomainSettingsAccount',

  props: {
    domain: Object as () => EditableDomain
  },

  data () {
    const paymentStatuses = [
      {
        key: null,
        value: '-'
      }, {
        key: 'demo',
        value: this.$t('core.paymentStatus.demo')
      }, {
        key: 'demo-expired',
        value: this.$t('core.paymentStatus.demo-expired')
      }, {
        key: 'pending',
        value: this.$t('core.paymentStatus.pending')
      }, {
        key: 'completed',
        value: this.$t('core.paymentStatus.completed')
      }, {
        key: 'expired',
        value: this.$t('core.paymentStatus.expired')
      }
    ]

    const paymentTypes = [
      {
        key: null,
        value: '-'
      }, {
        key: 'invoice',
        value: this.$t('core.paymentTypes.invoice')
      }, {
        key: 'stripe',
        value: this.$t('core.paymentTypes.stripe')
      }
    ]
    return {
      loading: false,
      errorMessages: '',
      paymentStatuses: paymentStatuses,
      paymentTypes: paymentTypes,
      existingUsers: [] as string[],
      domainQuotas: null! as DomainQuotas
    }
  },

  computed: {
    paymentStatus (): string | TranslateResult {
      return this.domain.payment_status && this.domain.payment_status !== '-'
        ? this.$t('core.paymentStatus.' + this.domain.payment_status)
        : '-'
    },
    paymentType (): string | TranslateResult {
      return this.domain.payment_type && this.domain?.payment_type !== '-'
        ? this.$t('core.paymentTypes.' + this.domain.payment_type)
        : '-'
    },
    userLimit (): string {
      return this.existingUsers.length + ' / ' + this.domain.user_limit
    },
    userLimitPercent (): number {
      if (this.domain!.user_limit! === 0) {
        return -1
      }
      return (this.existingUsers.length / this.domain!.user_limit!) * 100
    },
    getPlanName (): string {
      return this.domain!.plan ? getTextForUserLanguage(this.domain!.plan) : '-'
    },
    planSvgSuffix (): string {
      if (this.domain!.plan?.identifier.search('-s-') !== -1) {
        return 's'
      }
      if (this.domain!.plan?.identifier.search('-m-') !== -1) {
        return 'm'
      }
      if (this.domain!.plan?.identifier.search('-l-') !== -1) {
        return 'l'
      }
      if (this.domain!.plan?.identifier.search('-xl-') !== -1) {
        return 'xl'
      }
      return 'enterprise'
    },
    showStorageQuotaCriticalHint (): boolean {
      if (!this.domainQuotas) return false
      return this.domainQuotas.storage.available < this.domainQuotas.storage.quota * 0.15 && this.domainQuotas.storage.quota > -1
    }
  },

  methods: {
    openMail () {
      // TODO: localize texts
      location.href = 'mailto:vertrieb@simpl.training?subject=Support%20Domain%20' + this.domain.namespace + '&body='
    },
    openPhoneLink () {
      window.open('tel:+4966194940', '_blank')
    },
    openWebLink () {
      window.open('https://www.innovationmecom.de', '_blank')
    },
    formatDate (date: string): string {
      return (date) ? this.$d(stringToDate(date + ' 00:00:00') as Date, 'short') : ''
    }
  },

  apollo: {
    existingUsers: {
      query: LIST_USERS_MINIMAL,

      fetchPolicy: 'cache-and-network',

      variables: {
        first: 9999,
        filter: {
          filterBy: []
        }
      },

      update (result: Query): string[] {
        return result.users!.data.filter((user: User) => !user.is_super).map(user => user!.id!)
      },

      error (error: Error): void {
        console.error(error)
      }
    },
    domainQuotas: {
      query: DOMAIN_QUOTAS,

      fetchPolicy: 'cache-and-network',

      update (result: Query): any {
        return result.domainQuotas
      },

      error (error: Error): void {
        console.error(error)
      }
    }
  }
})































































import Vue from 'vue'

export default Vue.extend({
  name: 'AccessControlSidebar',

  props: {
    views: Array
  },

  data: () => ({
    mini: false
  }),

  created () {
    this.mini = this.$vuetify.breakpoint.xs
  }
})











































































































































































































import XLSX from 'xlsx'

import FileUploader from '@simpl/core/components/uploader/FileUploader.vue'
import { IMPORT_USERS } from '../graphql'
import { DomainQuotaItem, Role, Tag } from '@simpl/core/types/graphql'
import { getTextForUserLanguage } from '@simpl/core/utils'
import ActiveDomainTags from '@simpl/core/mixins/apollo/ActiveDomainTags'
import TagSelectFilter from '@simpl/core/mixins/utils/TagSelectFilter'
import DomainMarketSelection from '@simpl/core/components/DomainMarketSelection.vue'
import UserQuotaControl from '../mixins/UserQuotaControl'
import mixins from 'vue-typed-mixins'
import { isValidEmail } from '@simpl/core/utils/pattern-validation'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import { RoleExtended, RoleWithName, TagWithName } from '@simpl/core/types/extended-types'
import { unsavedChanges } from '@simpl/core/utils/message'

function initialState () {
  return {
    step: 1,
    roleId: null! as string | number,
    languagecode: '',
    selectedLanguageTagIds: [] as string[],
    selectedMarketTagIds: [] as string[],
    selectedUserGroupTagIds: [] as string[],
    invite: false,

    users: [] as any[],

    fileDetails: null! as any,
    fileVerificationErrors: null as string[] | null,

    showQuotaWarning: false,
    importing: false
  }
}

export default mixins(ActiveDomainTags, StringToColor, TagSelectFilter, UserQuotaControl).extend({
  name: 'ImportUsersDialog',

  model: {},

  components: {
    FileUploader,
    DomainMarketSelection
  },

  props: {
    value: Boolean,
    userGroupTags: Array as () => TagWithName[],
    languageTags: Array as () => TagWithName[],
    roles: Array as () => RoleWithName[]
  },

  data () {
    return initialState()
  },

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    languages (): Tag[] {
      return this.languageTags.filter((tag: Tag) => this.selectedLanguageTagIds.indexOf(tag.id) > -1)
    },
    tagIds (): string[] {
      return this.selectedLanguageTagIds.concat(this.selectedUserGroupTagIds, this.selectedMarketTagIds)
    },
    canImport (): boolean {
      return !!this.users.length &&
        !!this.roleId &&
        !!this.selectedLanguageTagIds.length &&
        !!this.selectedUserGroupTagIds.length &&
        !!this.languagecode &&
        !this.showQuotaWarning
    },
    canAdministrate (): boolean {
      return this.$permission.can(null, 'can-administrate')
    },
    currentUserMarkets (): Tag[] {
      return this.$store.state.auth.user.tags.filter((tag: Tag) => tag.category!.identifier === 'market')
    },
    publicPath (): string {
      return process.env.BASE_URL as string
    },
    availableRoles (): RoleExtended[] {
      return this.parseRolesQuota(this.roles)
    }
  },

  watch: {
    show (v) {
      if (!v) {
        Object.assign(this.$data, {
          ...initialState()
        })
      }
    },
    roleId (v) {
      if (this.roles.filter((role: Role) => role.id === v).length) {
        const identifier = this.roles.filter((role: Role) => role.id === v)[0].identifier

        if (this.domainQuotas.roles.filter((role: DomainQuotaItem) => role.type === identifier).length) {
          const quotaItem = this.domainQuotas.roles.filter((role: DomainQuotaItem) => role.type === identifier)[0]
          this.showQuotaWarning = quotaItem.quota !== -1 && quotaItem.available < this.users.length
        }
      }
    }
  },

  methods: {
    getTextForUserLanguage,

    onInput (file: File) {
      this.fileDetails = {
        name: file.name,
        size: file.size
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        let data: any = e.target!.result
        data = new Uint8Array(data)

        const workbook = XLSX.read(data, {
          type: 'array'
        })

        const firstSheet = workbook.Sheets[workbook.SheetNames[0]]
        const json = XLSX.utils.sheet_to_json(firstSheet, { header: 1 })

        if (this.isValid(json.filter((user: any) => user.length > 0))) {
          this.parseUsers(json.filter((user: any) => user.length > 0))
        } else {
          this.fileVerificationErrors = ['accessControl.message.invalidXLSFileStructure']
        }
      }

      reader.readAsArrayBuffer(file)
    },
    isValid (users: any[]) {
      const headers = users[0].map((header: any) => header.toString().toLowerCase())
      if (headers.length > 4) return false

      return ['email', 'firstname', 'lastname'].every((column: string) => {
        const columnIndex = headers.indexOf(column)
        if (columnIndex < 0) return false

        for (let i = 1; i < users.length; i++) {
          if (column === 'email') {
            if (!isValidEmail(users[i][columnIndex])) return false
          }

          if (column === 'username') {
            if (users[i][columnIndex].length && users[i][columnIndex].length < 6) return false
          }
        }
        return true
      })
    },
    parseUsers (users: any[]) {
      const headers = users[0].map((header: string) => header.toLowerCase())

      headers.forEach((header: string, index: number) => {
        for (let i = 1; i < users.length; i++) {
          if (index === 0) {
            this.users.push({
              [header]: users[i][index]
            })
          } else {
            this.users[i - 1][header] = users[i][index]
          }
        }
      })
    },
    async importUsers () {
      this.importing = true
      try {
        const response = await this.$apollo.mutate({
          mutation: IMPORT_USERS,
          variables: this.getMutationData()
        }) as any

        const result = response.data.importUsers

        this.$notification.publish('bottom', {
          message: result.success ? this.$t('accessControl.message.importSuccessful') : result.message,
          type: result.success ? 'success' : 'error',
          color: result.success ? 'success' : 'error'
        })
        if (result.success) {
          this.$emit('success')
        }
      } catch (e) {
        this.$notification.publish('bottom', {
          message: this.$t(e.message),
          type: 'error',
          color: 'error'
        })
      }
      this.importing = false
      this.show = false
    },
    getMutationData () {
      return {
        data: {
          users: this.users,
          languagecode: this.languagecode,
          role_id: this.roleId,
          tag_ids: this.tagIds,
          invite_now: this.invite
        }
      }
    },
    required (value: string | any[]) {
      return !!value?.length || this.$t('core.global.required')
    },
    async cancel () {
      this.show = this.users.length ? await unsavedChanges(this) : false
    }
  }
})


















































































































































import mixins from 'vue-typed-mixins'
import { getTextForUserLanguage } from '@simpl/core/utils/text'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'

import EditUserGroupDialog from '@simpl/access-control/components/EditUserGroupDialog.vue'
import { TagWithName } from '@simpl/core/types/extended-types'

import { DELETE_TAG, TAGS } from '../graphql'
import { TAG_CATEGORIES_MINIMAL } from '@simpl/core/graphql'
import { DataTableHeader } from '@simpl/core/types/table'
import { Query } from '@simpl/core/types/graphql'

export default mixins(StringToColor).extend({
  name: 'AccessControlUserGroupsList',

  components: {
    EditUserGroupDialog
  },

  data () {
    return {
      /** Displayed data */
      usergroups: [] as TagWithName[],
      loading: 0,
      usergroupCategoryId: null! as number,

      /** Dialog control flow */
      dialog: false,
      selected: null! as TagWithName,
      editing: false,

      /** Search and filter */
      searchValue: '',
      sortBy: [] as any[],
      sortDesc: [] as any[],
      count: 1,
      page: 1,
      itemsPerPage: 10
    }
  },

  computed: {
    headers (): DataTableHeader[] {
      /** Table view */
      return [{
        text: this.$t('core.global.id'),
        align: 'left',
        value: 'id',
        width: 70
      }, {
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('core.global.identifier'),
        value: 'identifier'
      }, {
        text: '',
        value: 'actions',
        width: 100,
        class: 'separate-left',
        sortable: false
      }]
    },
    computedHeaders (): DataTableHeader[] {
      return this.headers.filter(header => !(this.$vuetify.breakpoint.xs && header.value === 'actions'))
    }
  },

  apollo: {
    usergroupCategoryId: {
      query: TAG_CATEGORIES_MINIMAL,

      variables () {
        return {
          first: 9999,
          filter: {
            filterBy: [{
              name: 'identifier',
              values: ['usergroup']
            }]
          }
        }
      },

      update (result: Query): string {
        return result.tagCategories!.data[0].id
      },

      error (error: Error) {
        console.error(error)
      },

      loadingKey: 'loading'
    },

    usergroups: {
      query: TAGS,

      fetchPolicy: 'cache-and-network',

      skip () {
        return !this.usergroupCategoryId
      },

      variables () {
        return {
          filter: {
            search: {
              columns: ['texts:display_name'],
              query: this.searchValue
            },
            filterBy: [{
              name: 'category_id',
              values: this.usergroupCategoryId
            }]
          },
          orderBy: this.sortBy.map((key, index) => ({
            column: this.sortBy[index],
            order: this.sortDesc[index] ? 'DESC' : 'ASC'
          })),
          first: this.itemsPerPage,
          page: this.page
        }
      },

      update (result: Query): TagWithName[] {
        const { total, currentPage, perPage } = result.tags!.paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage

        return result.tags!.data.slice().map(tag => ({
          ...tag,
          name: getTextForUserLanguage(tag)
        }))
      },

      error (error: Error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    async refetch () {
      await this.$apollo.queries.usergroups.refetch()
    },
    add () {
      this.selected = null!
      this.editing = false
      this.dialog = true
    },
    edit (item: TagWithName) {
      this.selected = item
      this.editing = true
      this.dialog = true
    },
    async remove (id: string) {
      const answer = await this.$confirm({
        color: 'error',
        message: 'Are you sure? This action cannot be undone.',
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.delete'),
          color: 'error',
          answer: true
        }]
      })

      if (!answer) return

      this.loading += 1
      await this.$apollo.mutate({
        mutation: DELETE_TAG,
        variables: {
          id
        }
      })

      this.loading -= 1

      await this.refetch()
    }
  }
})

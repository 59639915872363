










































import mixins from 'vue-typed-mixins'
import { EditableDomain } from '../../types'
import { DomainEditUpdates } from './DomainSettings.vue'
import { debounce, getTextForLanguage } from '@simpl/core/utils'
import ActiveDomainTags from '@simpl/core/mixins/apollo/ActiveDomainTags'
import TextareaEditor from '@simpl/core/components/TextareaEditor.vue'

export default mixins(ActiveDomainTags).extend({
  name: 'DomainSettingsHomePage',

  components: {
    TextareaEditor
  },

  props: {
    domain: Object as () => EditableDomain,
    updates: Object as () => DomainEditUpdates
  },

  data () {
    return {
      selectedLanguageCode: this.$store.state.auth.user.languagecode
    }
  },

  computed: {
    headline: {
      get (): string {
        return getTextForLanguage(this.domain, this.selectedLanguageCode, 'welcome.headline')
      },
      set (v: string) {
        this.addTranslation('welcome.headline', v)
      }
    }
  },

  methods: {
    addTranslation: debounce(function (this: any, identifier: string, text: string) {
      if (!this.updates.lang[this.selectedLanguageCode]) {
        this.$set(this.updates.lang, this.selectedLanguageCode, {})
      }

      if (!this.updates.lang[this.selectedLanguageCode][identifier]) {
        this.$set(this.updates.lang[this.selectedLanguageCode], identifier, {})
      }

      this.updates.lang[this.selectedLanguageCode][identifier] = {
        languagecode: this.selectedLanguageCode,
        identifier: identifier,
        text: text
      }
    }, 500)
  }
})


















































































import Vue from 'vue'
import { ADD_ROLE, UPDATE_ROLE } from '../graphql'
import { createTextWithFallback, getTextForUserLanguage, nameToIdentifier } from '@simpl/core/utils/text'
import { unsavedChanges } from '@simpl/core/utils/message'
import { NewRoleInput, Permission, Role, UpdateRoleInput } from '@simpl/core/types/graphql'
import { TranslateResult } from 'vue-i18n'

const sortByAbility =
  (a: Permission, b: Permission) => a.ability > b.ability ? 1 : a.ability < b.ability ? -1 : 0

const mergeAbilities =
  (acc: any[], p: Permission) => {
    const [group, value] = p.ability.split('-')
    let existing = acc.find((c: any) => c.name === group)
    if (!existing) {
      existing = {
        id: -acc.length - 1,
        name: group,
        children: [],
        get selected () {
          return this.children.every((c: any) => c.selected)
        },
        set selected (v) {
          this.children.forEach((c: any) => (c.selected = v))
        }
      }
      acc.push(existing)
    }
    existing.children.push({
      id: p.id,
      name: value,
      selected: null
    })
    return acc
  }

export default Vue.extend({
  name: 'EditRoleDialog',

  model: {},

  props: {
    value: Boolean,
    id: [String, Number],
    edit: Boolean,
    preset: Object,
    roles: Array as () => Role[],
    permissions: Array as () => Permission[]
  },

  data () {
    return {
      loading: 0,
      originalName: '',
      internalName: '',
      originalParentRoleId: null as string | null,
      parentRoleId: null as string | null,
      initialIds: [] as string[],
      selected: [] as any[]
    }
  },

  computed: {
    title (): TranslateResult {
      return this.edit ? this.$t('accessControl.action.editRole') : this.$t('accessControl.action.createRole')
    },
    show: {
      get (): boolean { return this.value },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    groupedPermissions (): Permission[] | undefined {
      return this.permissions?.slice().sort(sortByAbility).reduce(mergeAbilities, [])
    },
    canSave (): boolean {
      const hasPermissions = this.selected.length > 0
      const hasName = !!this.internalName
      const hasParent = this.isBaseRole || !!this.parentRoleId

      return hasPermissions && hasName && hasParent
    },
    hasChanged (): boolean {
      const nameChanged = this.originalName !== this.internalName
      const idsEqual = this.initialIds.every(id => this.selected.includes(id)) &&
        this.selected.every(id => this.initialIds.includes(id))
      const parentChanged = this.originalParentRoleId !== this.parentRoleId

      return nameChanged || !idsEqual || parentChanged
    },
    identifier (): string {
      return (this.edit) ? this.preset.identifier : nameToIdentifier(this.internalName)
    },
    rolesExceptSelected (): Role[] {
      if (this.preset) {
        if (this.$store.state.auth.user.roles.filter((role: Role) => role.id !== this.preset.id).length === 0) {
          return []
        }
      }
      return this.preset ? this.roles.filter(role => role.id !== this.preset.id) : this.roles
    },
    isBaseRole: {
      get (): boolean {
        return this.parentRoleId === null
      },
      set (v: boolean) {
        this.parentRoleId = v ? null : this.rolesExceptSelected[0]?.id
      }
    }
  },

  watch: {
    show (v) {
      if (!v) return

      this.originalName = this.getPresetName()
      this.internalName = this.originalName

      this.parentRoleId = this.originalParentRoleId = this.preset?.parent?.id || null

      this.$nextTick(() => {
        if (this.preset) {
          this.selected = this.preset.permissions.map((p: Permission) => p.id)
        } else {
          this.selected = []
        }

        this.initialIds = this.selected.slice()
      })
    }
  },

  methods: {
    getMutationData (): { data: UpdateRoleInput | NewRoleInput } {
      const data: UpdateRoleInput | NewRoleInput = {
        domain: {
          connect: this.$store.state.auth.user.active_domain.id
        },
        identifier: this.identifier,
        parent: {
          connect: this.isBaseRole ? null : this.parentRoleId
        },
        permissions: {
          sync: this.selected
        },
        texts: {
          create: [
            ...createTextWithFallback(this.internalName)
          ]
        }
      }

      if (this.preset && this.edit) {
        (data as UpdateRoleInput).id = this.preset.id
      }

      return {
        data
      }
    },
    getPresetName (): string {
      let name = getTextForUserLanguage(this.preset, 'display_name', false) || ''

      if (this.preset && !this.edit) name += ' (copy)'

      return name
    },
    async close () {
      this.show = this.hasChanged ? await unsavedChanges(this) : false
    },
    async save () {
      this.loading += 1

      await this.$apollo.mutate({
        mutation: this.preset && this.edit ? UPDATE_ROLE : ADD_ROLE,
        variables: this.getMutationData()
      })

      this.loading -= 1
      this.show = false

      this.$emit('save')
    }
  }
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height content-padding"},[_c('Headline',{staticClass:"headline",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('accessControl.domain.domains'))+" ")]),_c('SearchFilterBar',{staticClass:"flex-grow-0 mb-6 mr-1",attrs:{"search-value":_vm.searchValue},on:{"update:searchValue":function($event){_vm.searchValue=$event},"update:search-value":function($event){_vm.searchValue=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$store.state.auth.user.is_super)?_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"color":"primary","fab":"","small":""},on:{"click":_vm.add}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('accessControl.domain.createDomain')))])])]},proxy:true}])}),_c('v-data-table',{attrs:{"loading":_vm.loading > 0,"headers":_vm.computedHeaders,"items":_vm.domains,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.count,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"multi-sort":"","group-by":"parent.namespace"},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"100%"}},[_c('v-btn',{attrs:{"text":"","icon":"","small":"","color":"white"},on:{"click":toggle}},[_c('v-icon',[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus'))])],1),(group)?_c('span',{staticClass:"mx-2 subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('accessControl.domain.props.parentDomain'))+": "+_vm._s(group)+" ")]):_c('span',{staticClass:"mx-2 subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('accessControl.domain.domains'))+" ")])],1)]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('div',{class:!!item.deleted_at ? 'grey--text' : '',style:(!!item.deleted_at ? 'text-decoration: line-through' : '')},[_vm._v(" "+_vm._s(item.id)+" ")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{class:!!item.deleted_at ? 'grey--text' : '',style:(!!item.deleted_at ? 'text-decoration: line-through' : '')},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.namespace",fn:function(ref){
var item = ref.item;
return [_c('div',{class:!!item.deleted_at ? 'grey--text' : '',style:(!!item.deleted_at ? 'text-decoration: line-through' : '')},[_vm._v(" "+_vm._s(item.namespace)+" ")])]}},{key:"item.payment_type",fn:function(ref){
var item = ref.item;
return [(item.payment_type)?_c('div',{class:!!item.deleted_at ? 'grey--text' : ''},[_vm._v(" "+_vm._s((item.payment_type !== '-') ? _vm.$t('core.paymentTypes.' + item.payment_type) : '-')+" ")]):_c('div',{class:!!item.deleted_at ? 'grey--text' : ''},[_vm._v(" - ")])]}},{key:"item.planEndDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.planEndDate)+" "),(_vm.isPlanEndDateNear(item.planEndDate))?_c('v-icon',{staticClass:"ml-1 mb-1",attrs:{"small":"","color":(_vm.isPlanEndDateOver(item.planEndDate)) ? 'red' : 'yellow'}},[_vm._v(" mdi-alert ")]):_vm._e()]}},{key:"item.payment_status",fn:function(ref){
var item = ref.item;
return [(!item.deleted_at && item.payment_status && item.payment_status !== 'demo' && item.payment_status !== '-')?_c('v-chip',{attrs:{"color":item.payment_status === 'completed' ? 'success' : item.payment_status === 'expired' ? 'red' : item.payment_status === 'demo-expired' ? 'orange' : 'warning'}},[_vm._v(" "+_vm._s(_vm.$t('core.paymentStatus.' + item.payment_status))+" ")]):_vm._e(),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.is_demo && item.payment_status !== 'demo-expired' && !item.deleted_at),expression:"item.is_demo && item.payment_status !== 'demo-expired' && !item.deleted_at"}],attrs:{"color":_vm.trialIsExpired(item.trial_ends_at) ? 'red' : 'blue'}},[(_vm.trialIsExpired(item.trial_ends_at))?_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-alert ")]):_vm._e(),_c('span',{staticClass:"pr-1"},[_vm._v("Demo")]),(item.trial_ends_at)?_c('span',{directives:[{name:"locale-date-format",rawName:"v-locale-date-format",value:(item.trial_ends_at),expression:"item.trial_ends_at"}],staticClass:"small-date"}):_vm._e()],1)]}},(!_vm.$vuetify.breakpoint.xs)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","icon":"","disabled":!!item.deleted_at},on:{"click":function($event){return _vm.edit(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('accessControl.domain.editDomain')))])]),(!!item.deleted_at)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","icon":"","disabled":!_vm.$store.state.auth.user.is_super},on:{"click":function($event){return _vm.revertDeletion(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-undo ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('accessControl.domain.revertDeletion')))])]):_vm._e(),(!item.deleted_at)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","icon":"","disabled":_vm.$store.state.auth.user.active_domain.id === item.id || !_vm.$store.state.auth.user.is_super},on:{"click":function($event){return _vm.changeActiveDomain(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-target ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('accessControl.domain.changeActiveDomain')))])]):_vm._e(),(!item.deleted_at)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","disabled":!_vm.$store.state.auth.user.is_super},on:{"click":function($event){return _vm.remove(item.id, item.namespace)}}},on),[(!item.deleted_at)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('accessControl.domain.deleteDomain')))])]):_vm._e(),(!!item.deleted_at)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 red",attrs:{"small":"","icon":"","disabled":!_vm.$store.state.auth.user.is_super},on:{"click":function($event){return _vm.deleteForever(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete-forever ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('accessControl.domain.deleteForever')))])]):_vm._e()],1)]}}:null],null,true)}),_c('EditDomainDialog',{attrs:{"selected-domain-id":_vm.selected ? _vm.selected.id : null,"existing-domains":_vm.domains},on:{"save":_vm.onSave},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
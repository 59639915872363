




























import mixins from 'vue-typed-mixins'
import MarketTags from '@simpl/core/mixins/apollo/MarketTags'
import { EditableDomain } from '../../types'
import { DataTableHeader } from '@simpl/core/types/table'

export default mixins(MarketTags).extend({
  name: 'DomainSettingsMarkets',

  props: {
    domain: Object as () => EditableDomain,
    showMarketValidationError: Boolean
  },

  computed: {
    headers (): DataTableHeader[] {
      return [{
        text: this.$t('core.global.id'),
        align: 'left',
        value: 'id',
        width: 70
      }, {
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('core.global.identifier'),
        value: 'identifier'
      }]
    }
  }
})

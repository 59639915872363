import { render, staticRenderFns } from "./PresetTile.vue?vue&type=template&id=9884a814&scoped=true&"
import script from "./PresetTile.vue?vue&type=script&lang=ts&"
export * from "./PresetTile.vue?vue&type=script&lang=ts&"
import style0 from "./PresetTile.vue?vue&type=style&index=0&id=9884a814&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9884a814",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCard,VCardText,VChip,VExpandTransition,VHover,VIcon,VImg,VResponsive,VRow,VSheet})

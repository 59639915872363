var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height content-padding"},[_c('Headline',{staticClass:"headline",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('cms.presets.tags'))+" ")]),_c('SearchFilterBar',{staticClass:"flex-grow-0 mb-6 mr-1",attrs:{"search-value":_vm.searchValue},on:{"update:searchValue":function($event){_vm.searchValue=$event},"update:search-value":function($event){_vm.searchValue=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('contentpreset-create'),expression:"'contentpreset-create'"}],staticClass:"ml-4",attrs:{"color":"primary","fab":"","small":""},on:{"click":_vm.add}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('cms.presets.createTag')))])])]},proxy:true}])}),_c('v-data-table',{attrs:{"loading":!_vm.contentPresetTags.length || _vm.loading > 0,"headers":_vm.computedHeaders,"items":_vm.contentPresetTags,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.count,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('div',[_vm._v(_vm._s(item.id))]),(_vm.$vuetify.breakpoint.xs)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":_vm.$vuetify.theme.dark,"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"action-buttons"},[_c('v-list-item',[_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('contentpreset-update'),expression:"'contentpreset-update'"}],attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1),_c('v-list-item',[_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('contentpreset-delete'),expression:"'contentpreset-delete'"}],attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1)],1)],1):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('contentpreset-update'),expression:"'contentpreset-update'"}],staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.edit(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('cms.presets.editTag')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('contentpreset-delete'),expression:"'contentpreset-delete'"}],attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('cms.presets.deleteTag')))])])],1)]}}])}),_c('EditPresetTagDialog',{attrs:{"edit":_vm.editing,"preselected":_vm.selected},on:{"save":_vm.refetch},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
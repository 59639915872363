


































import Vue from 'vue'
import AccessControlUserGroupsList from './AccessControlUserGroupsList.vue'
import AccessControlRoleList from './AccessControlRoleList.vue'
import AccessControlSidebar from '../components/AccessControlSidebar.vue'
import { LIST_PERMISSIONS } from '../graphql'
import { Permission, Query } from '@simpl/core/types/graphql'

export default Vue.extend({
  name: 'AccessControl',

  components: {
    AccessControlSidebar,
    AccessControlRoleList,
    AccessControlUserGroupsList
  },

  props: {
    view: {
      type: String,
      default: 'roles'
    }
  },

  breadcrumbs () {
    return [{
      text: this.view === 'roles' ? 'accessControl.sidebar.roles' : 'accessControl.sidebar.usergroups',
      to: null
    }]
  },

  data () {
    return {
      permissions: null! as Permissions[],
      loadingPermissions: 0
    }
  },

  computed: {
    views (): Record<string, any> {
      return [{
        key: 'roles',
        icon: 'mdi-head-cog',
        component: AccessControlRoleList
      }, {
        key: 'usergroups',
        icon: 'mdi-account-multiple',
        component: AccessControlUserGroupsList
      }]
    }
  },

  apollo: {
    permissions: {
      query: LIST_PERMISSIONS,

      update (data: Query): Permission[] {
        return data.permissions!.data.slice()
      },

      loadingKey: 'loadingPermissions'
    }
  }
})
















































































































































































import mixins from 'vue-typed-mixins'
import {
  FilterColumn,
  OrderByClause, PendingUser,
  SortOrder, Tag,
  User, ApprovePendingUsersInput, Query
} from '@simpl/core/types/graphql'
import { LIST_PENDING_USERS, APPROVE_PENDING_USERS, DELETE_PENDING_USER } from '../graphql'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import { getTextForUserLanguage } from '@simpl/core/utils'
import UserGroupSelectDialog from '../components/UserGroupSelectDialog.vue'
import { TagWithName } from '@simpl/core/types/extended-types'

export type PendingUserExtended = PendingUser & { disabled: boolean }

export default mixins(StringToColor).extend({
  name: 'PendingUsers',

  components: { UserGroupSelectDialog },

  props: {
    languageTags: Array as () => TagWithName[],
    userGroupTags: Array as () => TagWithName[]
  },

  data () {
    return {
      pendingUsers: [] as PendingUserExtended[],
      selectedUsers: [] as PendingUserExtended[],
      selectedUserGroupIds: [] as string[],

      showUserGroupsDialog: false,
      loading: 0,
      searchValue: '',
      sortBy: [] as any[],
      sortDesc: [] as any[],
      count: 1,
      page: 1,
      itemsPerPage: 10,
      filterBy: [] as FilterColumn[]
    }
  },

  computed: {
    headers (): Record<string, any>[] {
      /** Table view */
      const headers = [{
        text: this.$t('core.global.id'),
        align: 'left',
        value: 'id',
        width: 70,
        sortable: false
      }, {
        text: this.$t('core.global.mail'),
        value: 'email'
      }, {
        text: this.$t('core.global.firstName'),
        value: 'firstname'
      }, {
        text: this.$t('core.global.lastName'),
        value: 'lastname'
      }, {
        text: this.$t('core.global.language'),
        value: 'languagecode'
      }]
      if (this.$store.state.auth.domain.markets) {
        headers.push({
          text: this.$t('core.global.market'),
          value: 'market'
        })
      }
      return [...headers, {
        text: '',
        value: 'actions',
        class: 'separate-left',
        width: 50,
        sortable: false
      }]
    },
    computedHeaders (): Record<string, any>[] {
      return this.headers.filter(header => !(this.$vuetify.breakpoint.xs && header.value === 'actions'))
    },
    orderBy (): OrderByClause[] {
      return this.sortBy
        .map((key, index) => ({
          column: this.sortBy[index],
          order: this.sortDesc[index] ? SortOrder.Desc : SortOrder.Asc
        }))
    }
  },

  apollo: {
    pendingUsers: {
      query: LIST_PENDING_USERS,

      fetchPolicy: 'cache-and-network',

      variables (): Record<string, any> {
        return {
          filter: {
            search: {
              query: this.searchValue,
              columns: ['email', 'firstname', 'lastname']
            },
            filterBy: this.filterBy
          },
          orderBy: this.orderBy,
          page: this.page,
          first: this.itemsPerPage
        }
      },

      update (result: Query): PendingUserExtended[] {
        const { total, currentPage, perPage } = result.pendingUsers!.paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage

        return result.pendingUsers!.data.map(this.remapEntry)
      },

      error (error: Error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    getTextForUserLanguage,

    selectAll (v: boolean) {
      this.selectedUsers = []

      if (v) {
        this.selectedUsers = this.pendingUsers.filter((u: PendingUserExtended) => !u.disabled).map((x: any) => x.id)
      }
    },
    remapEntry (entry: PendingUser): PendingUserExtended {
      return {
        ...entry,
        disabled: entry.email_verified_at === null
      }
    },
    getLanguageTagName (identifier: string) {
      const languageTag = this.languageTags.find((lang: Tag) => lang.identifier === identifier)

      return getTextForUserLanguage(languageTag)
    },
    async approveSelectedUsers () {
      this.loading = 1

      const variables = {
        data: {
          ids: this.selectedUsers
        } as ApprovePendingUsersInput
      }

      if (this.selectedUserGroupIds.length) {
        variables.data.usergroups = this.selectedUserGroupIds
      }

      try {
        await this.$apollo.mutate({
          mutation: APPROVE_PENDING_USERS,
          variables: variables
        })

        this.$notification.publish('bottom', {
          message: this.$t('user.message.userApproved'),
          color: 'success',
          type: 'success'
        })

        this.selectedUsers = []
        this.selectedUserGroupIds = []
        await this.refetch()
      } catch (e: any) {
        this.$notification.publish('bottom', {
          message: this.$t(e.message),
          color: 'error',
          type: 'error'
        })
      }
      this.loading = 0
    },
    async deleteUser (user: User) {
      this.loading = 1
      try {
        await this.$apollo.mutate({
          mutation: DELETE_PENDING_USER,
          variables: {
            id: user.id
          }
        })

        this.$notification.publish('bottom', {
          message: this.$t('user.message.deleted', { firstname: user.firstname, lastname: user.lastname }),
          color: 'success',
          type: 'success'
        })

        await this.refetch()
      } catch (e) {
        console.log(e)
      }
      this.loading = 0
    },
    async refetch () {
      await this.$apollo.queries.pendingUsers.refetch()
    }
  }
})
